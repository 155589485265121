import { useState, useEffect } from 'react'
import { FC } from 'react';
import { Button, Box, TextField } from '@mui/material';
import './paywall.css'
import IntroFlowBack from 'Components/IntroFlowBack';
import CloseIcon from '../../assets/images/CloseIcon.png';
import CreditCardFilled from '../../assets/images/CreditCardFilled.svg';
import { loadStripe } from '@stripe/stripe-js';
import { MainAppReducer } from 'Types';
import CircularProgress from '@mui/material/CircularProgress';
import { Elements, useStripe, useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
 } from '@stripe/react-stripe-js';
import axios from 'axios';
import { planPricing, stripePriceIds } from 'Billing/Constants';
import { useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

interface PaymentProps {
  setShowPaywall: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPayment: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  planTypeSelected: string;
  exitAllowed: boolean;
  discountCodeValid: boolean | null;
  setDiscountCodeValid: React.Dispatch<React.SetStateAction<boolean | null>>;
  discountCode: string;
}

const Payment: FC<PaymentProps> = ({ setShowPaywall, setShowPayment, setShowConfirmation, planTypeSelected, exitAllowed, discountCode, discountCodeValid, setDiscountCodeValid }) => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const userData = data.userData

  let subTitle: string;
  let subFreq: string
  let subCost: number;
  let priceId: string;
  if (planTypeSelected == "monthly") {
    subFreq = "month"
    subTitle = "Monthly Plan"
    subCost =  discountCodeValid ? planPricing.monthly.withCode : planPricing.monthly.regular
    priceId = stripePriceIds.monthly
  } else if (planTypeSelected == "3-months") {
    subFreq = "3 months"
    subTitle = "3-Month Plan"
    subCost = discountCodeValid ? planPricing.threeMonths.withCode : planPricing.threeMonths.regular
    priceId = stripePriceIds.threeMonths
  } else {
    subFreq = "year"
    subTitle = "Annual Plan"
    subCost = discountCodeValid ? planPricing.annually.withCode : planPricing.annually.regular
    priceId = stripePriceIds.annually
  }

  const handleBack = () => {
    setShowPaywall(true)
    setShowPayment(false)
  }

  const sendPaymentMethodToServer = async (
    setIsLoading: any,
    stripe_customer_id: string,
    paymentMethodId: string,
    authToken: string | undefined) => {
    const response = await axios.patch<any>(
      `${baseUrl}stripe/update-plan`,
      { 
        customerId: stripe_customer_id,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        code: discountCodeValid ? discountCode : null, // Only pass coupon if valid (otherwise validator will fail it.)
      },
      { headers: {'Authorization': `Bearer ${authToken}`} }
    )
    const data = response.data
    if (data.error) {
      console.log("Error Submitting payment!", data.error);
    } else {
      setShowPayment(false)
      setShowConfirmation(true)
    }
    setIsLoading(false)
  }

  const CheckoutForm: React.FC = () => {
    const auth = useSelector((state: AuthReducer) => state.authReducer)
    const { jwtToken, user } = auth;
    const userFullName = user?.attributes?.name
    const userEmail = user?.attributes?.email
    const userPhoneNumber = user?.attributes?.phone_number
    const [isLoading, setIsLoading] = useState(false);
    const [cardholderName, setCardholderName] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isLoading) return
      setIsLoading(true)
      if (!stripe || !elements) {
        setIsLoading(false)
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        setIsLoading(false)
        return;
      }
  
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
          email: userEmail,
          phone: userPhoneNumber,
        },
      });
    
      if (error) {
        console.error(error);
        setIsLoading(false)
      } else {
        sendPaymentMethodToServer(
          setIsLoading,
          userData!.stripe_customer_id,
          paymentMethod.id,
          jwtToken,
        );
      }
      
    };

    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: '#ADB1B7',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: '400',
          padding: '14px 20px',
          letterSpacing: '0.15px',
          '::placeholder': {
            color: '#ADB1B7',
            fontFamily: 'Lato, sans-serif',
            fontStyle: 'normal',
            fontSize: '16px',
            fontWeight: '400',
            letterSpacing: '0.15px',
            padding: '14px 20px',
          },
          
        },
      },
    };

  const CARD_NUMBER_OPTIONS = {
      ...CARD_ELEMENT_OPTIONS,
      placeholder: 'Card Number',
  };
  
  const CARD_EXPIRY_OPTIONS = {
      ...CARD_ELEMENT_OPTIONS,
      placeholder: 'Expiration Date',
  };

  let inputClass
  if (discountCodeValid) {
    inputClass = 'discount-input plan-container-text valid-discount'
  } else {
    inputClass = 'discount-input plan-container-text valid-discount'
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <form onSubmit={handleSubmit}>
        <Box className="payment-form-container">
            <Box className="code-field-container" sx={{backgroundColor: 'rgba(129, 198, 156, 0.20)', borderRadius: '80px'}}>
              <Box className="plan-check">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M12.7275 2C7.22754 2 2.72754 6.5 2.72754 12C2.72754 17.5 7.22754 22 12.7275 22C18.2275 22 22.7275 17.5 22.7275 12C22.7275 6.5 18.2275 2 12.7275 2ZM10.7275 17L5.72754 12L7.13754 10.59L10.7275 14.17L18.3175 6.58L19.7275 8L10.7275 17Z" fill="#81C69C"/>
                </svg>
              </Box>
              <TextField
                fullWidth
                className={inputClass}
                label={`${subTitle}: $${subCost}/${subFreq}`}
                inputProps={{ readOnly: true }}
                sx={{ pointerEvents: 'none', }}
              />
              {discountCodeValid &&
                <Box className="code-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M6 1.37158C3.25 1.37158 1 3.62158 1 6.37158C1 9.12158 3.25 11.3716 6 11.3716C8.75 11.3716 11 9.12158 11 6.37158C11 3.62158 8.75 1.37158 6 1.37158ZM5 8.87158L2.5 6.37158L3.205 5.66658L5 7.45658L8.795 3.66158L9.5 4.37158L5 8.87158Z" fill="#2E5B3E"/>
                  </svg>
                  DISCOUNT CODE APPLIED
                </Box>
              }
            </Box>

            <Box className="card-container" sx={{position: 'relative', paddingLeft: '44px'}}>
              <img style={{position: 'absolute', left: '12px', top: '11px',}} src={CreditCardFilled} />
              <CardNumberElement options={CARD_NUMBER_OPTIONS} />
            </Box>
  
          <TextField 
            className="name-input"
            fullWidth
            placeholder="Cardholder Name"
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: '16px' }}>
            <Box className="card-container exp-cvv-container" >
              <CardExpiryElement options={CARD_EXPIRY_OPTIONS} />
            </Box>
            <Box className="card-container exp-cvv-container">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </Box>
          </Box>
  
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Box sx={{ fontStyle: 'normal', fontWeight: '400', fontSize: '24px', color: '#FFFFFF', marginRight: '20px', }}>
            Total: ${subCost}
          </Box>
          <Button type="submit" disabled={!stripe} style={buttonStyle} variant="contained">
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "COMPLETE PAYMENT"
            )}
          </Button>
        </Box>
        </Box>
      </form>
    </Box>
  );
  };

  return (
    <>
    <Box className="modal-outer"/>
    <Box className="modal-inner2">
      <IntroFlowBack handleBack={handleBack}/>
      {exitAllowed && 
        <Box sx={{
          position: 'absolute', top: '15px', right: '15px', '&:hover': { cursor: 'pointer' }}}
          onClick={() => {}}>
          <img src={CloseIcon}/>
        </Box>
      }
        <Box className="paywall-desc-container">
        <h3 className="payment-title">Enter your payment details</h3>
        </Box>
        <Elements stripe={stripePromise}>
          <CheckoutForm/>
        </Elements>
      </Box>
    </>
  )
}
export default Payment;

const buttonStyle = {
  width: '266px', 
  height: '51px',
  color: '#050B13', 
  fontSize: '16px',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  background: '#CA7C62', 
  letterSpacing: '2.5px',
  fontWeight: '700',
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}