import { Box } from '@mui/material';
import { useRef } from 'react'
import Survey from 'Containers/Roadmap/Survey';
import Home from './Home';
import Browse from './Browse';
import { MainAppReducer } from 'Types';
import { useSelector } from "react-redux";

const Repertoire = () => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)

  const repRef = useRef<HTMLImageElement>(null);
  return (
    <Box 
      sx={{
        display: 'flex', 
        // flexDirection: 'column',
        height: '100vh', 
        width: '100%',
      }} ref={repRef}
    >
      <Survey />
      <Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 100px)',}}>
        { data.repPageSelected == "Home" && <Home/> }
        { data.repPageSelected == "Browse" && <Browse/> }
      </Box>      
    </Box>
  )

}

export default Repertoire
