import React, { useCallback, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { Auth } from 'aws-amplify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fraction, NoteType } from 'opensheetmusicdisplay';
import Staff from 'Components/Staff';
import MusicXML from 'Models/MusicXML';
import {Button, Box, Input, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, CircularProgress} from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import {List} from 'immutable'
import { find } from 'lodash';
import * as appActions from 'Actions/app'
import * as eventActions from 'Actions/events';
import { v4 as uuid } from 'uuid';
import Phrase from 'Models/Phrase';
import { MusicXMLStream } from 'Models/MusicXMLStream';
import TimeKeeper from 'Models/TimeKeeper';
import ITimeKeeper from 'Models/ITimeKeeper';

import { TickNoteEvent } from 'Utils/CustomEvents';
import { IntervalTimer } from 'Utils/IntervalTimer';
import { divisionsPerMeasureToNoteTypeIn4Over4 } from 'Utils';

import 'react-piano/dist/styles.css';
import './MainApp.css';
import { useSelector, useDispatch } from 'react-redux'
import EventStream from 'Models/EventStream';
import { MainAppReducer, UserLevelData, LevelStatus } from 'Types';

import { TimingOffsetsConfig } from 'Models/EventStream';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { useLocation } from 'react-router-dom';

import { useMidiContext } from 'Contexts/MidiContext';
import { useNavigate } from "react-router-dom";
import Scheduler2 from 'Utils/Scheduler';
import TimeSignature from 'Models/TimeSignature';
import { AuthReducer } from 'Types/AuthTypes';
import axios from 'axios';
// import AxiosRetrier from 'Utils/AxiosRetrier';
import { ConstructionOutlined } from '@mui/icons-material';

// const axios = new AxiosRetrier();

const allowRepeatPhrases = false
const measuresPerPhrase = 4

const minTempoForChevronFlash = 72

let musicXMLStream: MusicXMLStream = new MusicXMLStream();

// const createWorker = createWorkerFactory(() => import('WebWorkers/Scheduler'));

async function useComponentWillUnmount(cleanupCallback = () => {}) {
  const callbackRef = React.useRef(cleanupCallback)
  callbackRef.current = cleanupCallback // always up to date
  React.useEffect(() => {
    return () => {callbackRef.current()}
  }, [])
}

interface MainAppProps {
  showKeyboard: boolean;
  setAccuracy: (x: number) => void
  setShowAccuracy: (x:boolean) => void
  setAllowChevrons: (x:boolean) => void
  cursorIsVisible: boolean
  setCurrentTier: (x:number) => void
  currentTier: number
  updateCurrentTier: (x: number) => void
  // musicXmlSvgWidth: number
}
const canWakeLock = () => 'wakeLock' in navigator;


// function MainApp(props: MainAppProps) {
function MainApp(props: MainAppProps) {

  const { awsRum } = useAWSRumContext();

  const navigate = useNavigate(); 

  let location = useLocation();

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const dispatch = useDispatch();
  const wakeLock = React.useRef<any>(null);
  const [ isWakeLocked, setIsWakeLocked ] = React.useState(false);
  const isVisible = usePageVisibility()

  const { 
    setAccuracy,
    setAllowChevrons,
    currentTier, 
    updateCurrentTier,
    setCurrentTier,
    setShowAccuracy,
    cursorIsVisible,
    // musicXmlSvgWidth
  } = props

  // const { timeKeeper, midiStream } = props.midiProps
  const { tempo, levelSelect, unitSelect } = data
  
  // FIXME: Tempo should be grabbed from redux only - not set as its own state. Or removed from redux.
  // const [tempo, setTempo] = React.useState(data.tempo)
  const [initialized, setInitialized] = React.useState(false);
  // const [ musicXmlSvgWidth, setMusicXmlSvgWidth] = React.useState(960)
  const musicXmlSvgWidth = React.useRef(960);
  const min960 = useMediaQuery('(min-width:960px)');
  const min1100 = useMediaQuery('(min-width:1100px)');
  const min1300 = useMediaQuery('(min-width:1300px)');
  const min1650 = useMediaQuery('(min-width:1650px)');
  const min1850 = useMediaQuery('(min-width:1850px)');


  React.useEffect(()=>{
    if(min1850) {
      musicXmlSvgWidth.current = 1600
    } else if(min1650) {
      musicXmlSvgWidth.current = 1440
    } else if(min1300) {
      musicXmlSvgWidth.current = 960
    } else if(min1100) {
      musicXmlSvgWidth.current = 800
    } else if(min960) {
      // this might not work - need to add additional choices in the parsing lambda. I don't think we support this anyway
      musicXmlSvgWidth.current = 800
    }
  },[min960, min1100, min1300, min1650, min1850])
  

  
  const [phraseParts, setPhraseParts ] = React.useState<List<Phrase | undefined>>(List([undefined, undefined, undefined, undefined]));

  // const [showKeyboard ] = React.useState(props.showKeyboard == false ? false : true);
  // const [showNextPhraseButton ] = React.useState(props.showKeyboard == false ? false : true);
  // const [showStartStopButtons] = React.useState(props.showKeyboard == false ? false : true);
  const [musicLoaded, setMusicLoaded] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [topPhraseStartTimestamp, setTopPhraseStartTimestamp] = React.useState(100000)
  const [bottomPhraseStartTimestamp, setBottomPhraseStartTimestamp] = React.useState(100000)
  const [tickNoteType] = React.useState(NoteType._64th);
  // const [isEligible, setIsEligible] = React.useState(true); // Whether user is eligable for a chevron
  // const [currentPhraseIsEligable, setCurrentPhraseIsEligible] = React.useState(true);
  // not sure why this is necessary
  // but it works... https://stackoverflow.com/questions/71447566/react-state-variables-not-updating-in-function
  const phrasePartsRef = React.useRef<List<Phrase | undefined>>(phraseParts);

  // the initial purpose of this was to have a way to update lower components at will, but it's not used.
  const [updateKey, setUpdateKey] = React.useState(0);

  const accumulatedTimeRef = React.useRef(0);
  const lastMeasuredTimeRef = React.useRef(0);


 
  const keyCallbackRef = React.useRef<((ev: KeyboardEvent) => void) | null>(null)

  // Last measure that the user fully played.
  const lastPlayedMeasureRef = React.useRef(-1)
  // Last updated phrase index 0-3
  const lastUpdatedIndexRef = React.useRef(-1)
  // What measure offset should the next phrase have
  const nextPhraseStartTimestampRef = React.useRef(0)
  // When we reach this measure, we should grab and update the next new phrase.
  // Subtract one here since we really want to switch at measure 6, but we use 0-based indexing.
  const nextPhraseUpdateMeasureRef = React.useRef(measuresPerPhrase * 1.5 - 1)
  const nextPhraseStartMeasureRef = React.useRef(0)
  // This interval timer isn't related to the in-game audiocontext timer.
  // it doesn't have to be as precise. Also, the scheduler only schedules in ticks.
  const recordPlayTimeInterval = React.useCallback(() => {
    if(
      data.levelSelect !== undefined && 
      data?.currentUserLevelData?.current_tier !== undefined
    ) {
      const now = new Date().getTime()
      accumulatedTimeRef.current = now - lastMeasuredTimeRef.current + accumulatedTimeRef.current
      lastMeasuredTimeRef.current = now;
    }
  }, [data.levelSelect, data?.currentUserLevelData?.current_tier,  data.levelData])

  // by breaking up the recording of play time and the sending of play time we can be more accurate
  // with the recording without ddosing the backend.
  const sendPlayTimeInterval = React.useCallback(() => {
    if(
      data.levelSelect !== undefined && 
      data?.currentUserLevelData?.current_tier !== undefined
    ) {
      if(process.env.REACT_APP_NODE_ENV === 'prd' || process.env.REACT_APP_NODE_ENV === 'dev' || process.env.REACT_APP_NODE_ENV === 'local') {
        dispatch(eventActions.levelPlayingEventAction(
          accumulatedTimeRef.current,
          data.levelData[levelSelect].level_number,
          data?.currentUserLevelData?.current_tier,
          data.playSessionId || "unknown"
        ))
      }
    }
  }, [data.levelSelect, data?.currentUserLevelData?.current_tier,  data.levelData])
  
  const recordPlayTimeIntervalTimer = React.useRef<IntervalTimer | undefined>();
  const sendPlayTimeIntervalTimer = React.useRef<IntervalTimer | undefined>();
  const lastPlayedNoteTimer = React.useRef<NodeJS.Timeout | undefined>();

  const [timeKeeper, setTimeKeeper] = React.useState(
    new TimeKeeper(),
  )
  const [midiStream] = React.useState(
    new EventStream(timeKeeper, new TimingOffsetsConfig()),
  )
  const noteOff = React.useCallback((ev: any) => {
    midiStream.handleNoteOffEvent(ev.note)
  },[midiStream]);

  const noteOn = React.useCallback((ev: any) => {
    console.log(ev)
    if (ev.velocity !== 0) {
      setShowAccuracy(true)
      clearTimeout(lastPlayedNoteTimer?.current)
      if(process.env.REACT_APP_CURSOR_TIMEOUT) {
        lastPlayedNoteTimer.current = setInterval(() => {
          dispatch(appActions.setLessonPlaying({lessonPlaying: false}))
        },10000)
      }
      midiStream.handleNoteOnEvent(ev.note, ev.velocity)
    } else {
      noteOff(ev) // call the note off logic becasue velocity is 0.
    }
  },[midiStream, noteOff]);

  const listener = React.useRef({
    name: "eventListener",
    noteOn,
    noteOff
  });
  const {addListener, removeListener, clearListeners, initializeMidiInput} = useMidiContext()

  let [scheduler2, setScheduler2 ]= React.useState(new Scheduler2(timeKeeper));

  const resetScheduler = React.useCallback(()=>{
    scheduler2.reset()
    setScheduler2(new Scheduler2(timeKeeper))
  },[scheduler2, timeKeeper])


  async function lockWakeState() {
    if(!canWakeLock()) return;
    try {
      wakeLock.current = await (navigator as any).wakeLock.request();
      wakeLock.current.addEventListener('release', () => {
        setIsWakeLocked(false);
      });
      setIsWakeLocked(true);
    } catch(error) {
      // console.error('Failed to lock wake state');
      // console.error(error);
    }
  }
  
  React.useEffect(()=>{
    if(!isWakeLocked && isVisible) {
      lockWakeState()
    }
  },[isWakeLocked, isVisible])

  React.useEffect(() => {
    scheduler2?.updateWorkerState({phrasesTilNextTier: data.phrasesTilNextTier})
  }, [data.phrasesTilNextTier, scheduler2])


  const getPhraseInfo =  React.useCallback(async (tier: number) => {
    const levelNumber = data.levelData[levelSelect].level_number
    // instead of using the auth context, I'm using amplify auth. It seems like the currying done
    // in the timekeeper function is leading to the token rotation not working as expected.
    // Theoretically this should work even if that doesn't. But it's possible it will have the same issue.
    const user = await Auth.currentAuthenticatedUser();
    const options = {
      url: process.env.REACT_APP_BACKEND_URL + `/api/v1/phrases/gen-phrase?levelNumber=${levelNumber}&tierNumber=${tier}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`
      }
    }
    let response = await axios
      .request(options)
    return ({
      uuid: response['data']['s3_music_xml_id'],
      s3_music_xml_url: response['data']['s3_music_xml_url'],
      svg_url: response['data']['music_xml_url_pointers'][`svg_url_${musicXmlSvgWidth.current}`],
      svg_url_timesig: response['data']['music_xml_url_pointers'][`svg_url_timesig_${musicXmlSvgWidth.current}`],
      json_data_url: response['data']['music_xml_url_pointers'][`json_data_url_${musicXmlSvgWidth.current}`],
      json_data_url_timesig: response['data']['music_xml_url_pointers'][`timesig_json_data_url_${musicXmlSvgWidth.current}`],
      time_signature_numerator: response['data']['time_signature_numerator'],
      time_signature_denominator: response['data']['time_signature_denominator']
    })
    
  }, [data.levelSelect, data.levelData, auth.jwtToken, musicXmlSvgWidth])
  
  const getNextPhrase = React.useCallback(async function genNextPhrase(tier: number, timesig: boolean = false){
      let num = Math.round(Math.random() * 10)
      let file = `MuseFlow L14 T7 - Phrase ${num}.musicxml`
      // const url = name ? process.env.PUBLIC_URL + '/' +  name : process.env.PUBLIC_URL + '/' + file;
      // let file = 'one whole note.musicxml'
      const phraseData = await getPhraseInfo(tier);
      let [musicXmlRes, cursorDataRes, cursorDataTimesigRes] = await Promise.all([
        axios.get(phraseData.s3_music_xml_url),
        axios.get(phraseData.json_data_url),
        axios.get(phraseData.json_data_url_timesig)
      ])
      let musicXML = new MusicXML(musicXmlRes.data, [new TimeSignature(phraseData.time_signature_numerator, phraseData.time_signature_denominator)]);
      let phrase = new Phrase(phraseData.uuid, musicXML, phraseData.svg_url, phraseData.svg_url_timesig, cursorDataRes.data, cursorDataTimesigRes.data);

      return phrase
  }, [getPhraseInfo, auth.jwtToken])

  const updatePhraseParts = React.useCallback(function updatePhraseParts(newPhrasePart: Phrase | undefined){
    // Mod 4 is hard-coded here since we have 4 phrases total, one each visible on top and bottom,
    // and one each invisible (under the visible phrase) on top and bottom
    const nextIndexRef = (lastUpdatedIndexRef.current + 1) % 4

    // Are we setting a div on top or bottom?
    if (nextIndexRef % 2 == 0) {
      setTopPhraseStartTimestamp(nextPhraseStartTimestampRef.current)
    } else {
      setBottomPhraseStartTimestamp(nextPhraseStartTimestampRef.current)
    }
    newPhrasePart?.setStartTimestamp(nextPhraseStartTimestampRef.current)

    timeKeeper.setTimeSignatures(nextPhraseStartMeasureRef.current, newPhrasePart?.musicXML?.timeSignatures)

    nextPhraseStartTimestampRef.current += measuresPerPhrase * timeKeeper.measureDurationAt(0)
    nextPhraseStartMeasureRef.current += measuresPerPhrase
    phrasePartsRef.current = phrasePartsRef.current.set(nextIndexRef, newPhrasePart)
    // console.log("setting next timestamp ref to " + nextPhraseStartTimestampRef.current + " for phrase " + newPhrasePart?.uuid)

    setPhraseParts(phrasePartsRef.current)
    lastUpdatedIndexRef.current = nextIndexRef


  }, [])

  const getTiersForLevel = React.useCallback(async function getTiersForLevel(){
    const levelNumber =  data.levelData[data.levelSelect].level_number
    const options = {
      url: process.env.REACT_APP_BACKEND_URL + `/api/v1/phrases/tiers-by-level?levelNumber=${levelNumber}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${auth.jwtToken}`
      }
    }
    let response = await axios
      .request(options)
    return response['data']
  }, [data.levelSelect, data.levelData, auth.jwtToken])

  const getAtLeastNMeasures = React.useCallback(async function getAtLeastNMeasures(numMeasures: number, tier: number = currentTier){
    let startingLength = musicXMLStream.totalMeasures;
    while(musicXMLStream.totalMeasures < startingLength + numMeasures) {
      musicXMLStream.addPhrase(await getNextPhrase(tier));
    }
  }, [currentTier, getNextPhrase, getNextPhrase])


  const getNextNMeasures = React.useCallback(function getNextNMeasures(numberMeasures: number, tier: number){
    getAtLeastNMeasures(numberMeasures, tier).then(() => {
      let nextPhrase = musicXMLStream.nextPhrase();
      if(allowRepeatPhrases) {
        updatePhraseParts(nextPhrase)
      } else if(find(phraseParts.toArray(), phrasePart => phrasePart?.musicXML.hash === nextPhrase?.musicXML?.hash)) {
        console.log("found repeat - requerying")
        getNextNMeasures(numberMeasures, tier); // no repeats
      } else {
        updatePhraseParts(nextPhrase)
      }
    
    });
  }, [updatePhraseParts, getAtLeastNMeasures])

  const handleFileUpload = (evt: any) => {
    const file: File = evt.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      if (!reader?.result) {
        return
      }

      const musicxml = new MusicXML(reader.result.toString(), [])
      // const phrase = new Phrase(undefined, musicxml, undefined, undefined, undefined, undefined)
      // musicXMLStream = new MusicXMLStream()

      // musicXMLStream.addPhrase(phrase)

      // // Reset state tracking when a file is uploaded.
      // lastPlayedMeasureRef.current = -1
      // lastUpdatedIndexRef.current = -1
      // nextPhraseStartTimestampRef.current = 0
      // nextPhraseUpdateMeasureRef.current = measuresPerPhrase * 1.5 - 1
      // nextPhraseStartMeasureRef.current = 0
      // midiStream.reset()
      // if(musicXMLStream.inputPhrases.length > 2) {
      //   updatePhraseParts(musicXMLStream.nextPhrase() as Phrase)
      //   updatePhraseParts(musicXMLStream.nextPhrase() as Phrase)
      // }
    }
    reader.readAsText(file)
  }

  React.useEffect(() => {
    if(!musicLoaded && phraseParts.size >= 2) {
      setMusicLoaded(true);
    }
  }, [musicLoaded, phraseParts])

  const incrementPhrase = React.useCallback(function incrementPhrase(ev: any){
    if (!ev.detail.timeKeeper.getIsCountingIn()) {
      // We want the last measure that was FULLY PLAYED, hence the floor and subtract 1.
      // We subtract 1 since if we just got to a new measure the LAST measure was fully played.
      // Also wrap the whole thing in Math.max(), since once we hit the first measure after the count in,
      // we'd actually move back a measure if we stopped playback during the first measure after count-in without it.
      lastPlayedMeasureRef.current = Math.max(lastPlayedMeasureRef.current, 
        Math.floor(ev.detail.ticks / ev.detail.timeKeeper.getTicksPerMeasure()) - 1)

      // Check if the user has fully played enough measures that we should grab the next phrase they'll play.
      if (lastPlayedMeasureRef.current >= nextPhraseUpdateMeasureRef.current) {
        getNextNMeasures(measuresPerPhrase, ev.detail.workerState.tier)
        nextPhraseUpdateMeasureRef.current += measuresPerPhrase
      }
    }
  }, [getNextNMeasures]);

  const startMidiStreamUpdateLoop = React.useCallback(function startMidiStreamUpdateLoop(){
    scheduler2?.setIntervalByTick('updateMidiStream', (ev: typeof TickNoteEvent) => {
      midiStream.update()
    }, NoteType._32nd, 1)
  },[midiStream, scheduler2])

  const startPhraseLoop = React.useCallback(function startPhraseLoop() {
    scheduler2.setTimeoutByTick('phraseLoopTimeout', ()=> {
      getNextNMeasures(measuresPerPhrase,currentTier)
      scheduler2?.setIntervalByTick('phraseLoop', function phraseLoop(ev: typeof TickNoteEvent) {
        incrementPhrase(ev);
        // quarter are easiet to add
      },  divisionsPerMeasureToNoteTypeIn4Over4(timeKeeper.getTimeSignature().denominator), (timeKeeper.getTimeSignature().numerator * 4), timeKeeper.getTimeSignature().numerator)
      // TODO: will need to make this relative phrases per tier
    }, divisionsPerMeasureToNoteTypeIn4Over4(timeKeeper.getTimeSignature().denominator), (timeKeeper.getTimeSignature().numerator * 5))
   
  }, [currentTier, getNextNMeasures, incrementPhrase, scheduler2, timeKeeper])

  const startAccuracyLoop = React.useCallback(()=>{
    // scheduler2?.setTimeoutByTick('setAccuracyIntervalTimeout', ()=> {
      scheduler2?.setIntervalByTick('accuracyLoop',function accuracyLoop(ev: typeof TickNoteEvent) {
        const accuracy = midiStream.calcAccuracyV2();
        setAccuracy(accuracy)
        // every beat we check eligibility. If it's less than 95 then nope. This is reset every phrase (4 measures)
        // if accuracy dips below .95 at any point, the current phrase is not eligible for a chevron
        // However, we don't count the very first note.
        if(accuracy < 95 && midiStream.accuracyQueueLength() > 1) {
          scheduler2?.updateWorkerState({isEligible: false})
        }
      }, divisionsPerMeasureToNoteTypeIn4Over4(timeKeeper.getTimeSignature().denominator), 1, 0);
    // }, NoteType.WHOLE, 4)
  },[midiStream, scheduler2, setAccuracy, timeKeeper])

  useEffect(() => {
    scheduler2?.updateWorkerState({tempo: tempo})
    if (tempo < minTempoForChevronFlash) {
      scheduler2?.updateWorkerState({isEligible: false})
      setAllowChevrons(false)
    }
  }, [scheduler2, setAllowChevrons, tempo])

  const startChevronLoop = React.useCallback(() => {
    scheduler2?.setTimeoutByTick("chevronLoopStartTimeout",()=>{
      const beatsPerMeasure = timeKeeper.getTimeSignature()?.numerator;
      scheduler2?.setIntervalByTick('chevronLoop', function chevronLoop(ev: typeof TickNoteEvent){
        const accuracy = midiStream.calcAccuracyV2();
        if(accuracy >= 95 && ev.detail.workerState.isEligible && midiStream.rollBackWindowFull() && ev.detail.workerState.phrasesTilNextTier !== 0) {
          dispatch(appActions.updatePhrasesTilNextTier(ev.detail.workerState.phrasesTilNextTier - 1))
        } else if (accuracy < 75 && ev.detail.workerState.phrasesTilNextTier !== 4) {
          dispatch(appActions.updatePhrasesTilNextTier(ev.detail.workerState.phrasesTilNextTier + 1))
        }
        
        if(accuracy >= 95 && ev.detail.workerState.tempo >= minTempoForChevronFlash) {
          // If not eligible, and >= 95 we can start this phrase as eligible. 
          scheduler2?.updateWorkerState({isEligible: true})
          setAllowChevrons(true)
        }

      }, NoteType.QUARTER, beatsPerMeasure * 4, 0);
    },NoteType.WHOLE, 1)
  }, [dispatch, midiStream, scheduler2, setAllowChevrons, timeKeeper])
  
  const onStop = React.useCallback(() => {
    recordPlayTimeIntervalTimer?.current?.pause()
    recordPlayTimeIntervalTimer.current = undefined;
    sendPlayTimeIntervalTimer?.current?.pause()
    sendPlayTimeIntervalTimer.current = undefined;
    clearTimeout(lastPlayedNoteTimer.current)
    lastPlayedNoteTimer.current = undefined;
    scheduler2?.pauseTicks()
  }, [scheduler2])
  
  const onStart = React.useCallback(async () => {
    if(!started) {
      await scheduler2?.init({tier: data?.currentUserLevelData?.current_tier, isEligible: false, phrasesTilNextTier:4, tempo:tempo});
      // this has to be initialized after the page is loaded and the user has made an action on the screen
      startMidiStreamUpdateLoop()
      startPhraseLoop()
      startAccuracyLoop()
      startChevronLoop()
      setStarted(true);
    } 
    if(!recordPlayTimeIntervalTimer?.current) {
      // set last measted time ref on play in order to zero out the time spent during pause.
      lastMeasuredTimeRef.current = new Date().getTime()
      recordPlayTimeIntervalTimer.current = new IntervalTimer(recordPlayTimeInterval, 1000)
      recordPlayTimeIntervalTimer?.current?.start();
    } else {
      // after refactoring to create a new interval function with a new start time after each pause, this really shouldn't run,
      // but I'm going to keep the code anyway just in case.
      recordPlayTimeIntervalTimer?.current?.resume();
    }
    if(!sendPlayTimeIntervalTimer?.current) {
      // set last measted time ref on play in order to zero out the time spent during pause.
      sendPlayTimeIntervalTimer.current = new IntervalTimer(sendPlayTimeInterval, 10000)
      sendPlayTimeIntervalTimer?.current?.start();
    } else {
      // after refactoring to create a new interval function with a new start time after each pause, this really shouldn't run,
      // but I'm going to keep the code anyway just in case.
      sendPlayTimeIntervalTimer?.current?.resume();
    }
    
    if(process.env.REACT_APP_CURSOR_TIMEOUT) {
      clearTimeout(lastPlayedNoteTimer.current)
      lastPlayedNoteTimer.current = setInterval(() => {
        dispatch(appActions.setLessonPlaying({lessonPlaying: false}))
      },10000) 
    }
    await scheduler2?.unpauseTicks(tempo, 1, lastPlayedMeasureRef.current + 1, tickNoteType)
  }, [data?.currentUserLevelData?.current_tier, onStop, scheduler2, startAccuracyLoop, startChevronLoop, startMidiStreamUpdateLoop, startPhraseLoop, started, tempo, tickNoteType, timeKeeper, recordPlayTimeInterval, sendPlayTimeInterval])


  React.useEffect(() => {
    if (initialized && data.lessonPlaying){
      onStart()
    } else {
      onStop()
    }
  }, [data.lessonPlaying])

  React.useEffect(() => {
    timeKeeper.setBpm(tempo)

    // Remove previous listener, if there was one.
    if (keyCallbackRef.current != null) {
      document.removeEventListener('keyup', keyCallbackRef.current)
    }

    const listener = (e: KeyboardEvent) => {
      e.preventDefault()
      // Handle spacebar events (pause/unpause)
      if (e.key == ' ' && data.phrasesTilNextTier !== 0) {
        dispatch(appActions.setLessonPlaying({lessonPlaying: !data.lessonPlaying}))
      }
    }

    keyCallbackRef.current = listener
    document.addEventListener('keyup', listener)

  }, [tempo, data.lessonPlaying, data.phrasesTilNextTier])

  const initSpacebarListener = React.useCallback(()=> {
     // Remove previous listener, if there was one.
     if (keyCallbackRef.current == null) {
      const listener = (e: KeyboardEvent) => {
        console.debug("spacebar listener called")
        e.preventDefault()
        // Handle spacebar events (pause/unpause)
        if (e.key == ' ' && data.phrasesTilNextTier !== 0) {
          console.debug("spacebar listener dispatched lesson playing event")
          dispatch(appActions.setLessonPlaying({lessonPlaying: !data.lessonPlaying}))
        }
      }

      keyCallbackRef.current = listener
      document.addEventListener('keyup', listener)
    }
  }, [data.lessonPlaying, data.phrasesTilNextTier, dispatch])


  // When do I set this? 4 bars...
  // setAllowChevrons()

  const resetChevronLoop = React.useCallback(() => {
    scheduler2.clearTimeoutFn('chevronLoopStartTimeout')
    scheduler2.clearIntervalFn('chevronLoop')
    scheduler2.printIntervals()
    scheduler2.printTimeouts()
    startChevronLoop()
  }, [scheduler2])

  const reset = React.useCallback(async ()=>{
    if(data?.currentUserLevelData?.current_tier) {
      timeKeeper.resetTime()
      phrasePartsRef.current.set(0, undefined)
      phrasePartsRef.current.set(1, undefined)
      phrasePartsRef.current.set(2, undefined)
      phrasePartsRef.current.set(3, undefined)
      setTopPhraseStartTimestamp(100000)
      setBottomPhraseStartTimestamp(100000)
      resetScheduler()
      clearTimeout(lastPlayedNoteTimer?.current)
      lastPlayedNoteTimer.current = undefined;
      recordPlayTimeIntervalTimer.current?.pause()
      recordPlayTimeIntervalTimer.current?.clear()
      recordPlayTimeIntervalTimer.current = undefined
      sendPlayTimeIntervalTimer.current?.pause()
      sendPlayTimeIntervalTimer.current?.clear()
      sendPlayTimeIntervalTimer.current = undefined
      setAllowChevrons(false)
      setAccuracy(100);
      dispatch(appActions.updatePhrasesTilNextTier(4));
      lastPlayedMeasureRef.current = -1
      lastUpdatedIndexRef.current = -1
      nextPhraseStartTimestampRef.current = 0
      nextPhraseUpdateMeasureRef.current = measuresPerPhrase * 1.5 - 1
      nextPhraseStartMeasureRef.current = 0
      accumulatedTimeRef.current = 0
      midiStream.reset();
      musicXMLStream.reset();
      dispatch(appActions.setPlaySessionId(uuid()))
      setStarted(false);
      if(musicXMLStream.length() === 0) {
        await Promise.all([
          getNextNMeasures(measuresPerPhrase, data?.currentUserLevelData?.current_tier || 1),
          getNextNMeasures(measuresPerPhrase, data?.currentUserLevelData?.current_tier || 1)
        ])
      }
    }
  
  },[data?.currentUserLevelData?.current_tier, setAllowChevrons, setAccuracy, dispatch, midiStream, scheduler2, resetChevronLoop, getNextNMeasures])

  React.useEffect(() => {
    if(data?.currentUserLevelData?.current_tier !== undefined && initialized) {
      onStop()
      reset()
    }
  }, [data?.currentUserLevelData?.current_tier])

  React.useEffect(() => {
    if(initialized) {
      onStop()
      setCurrentTier(1)
      reset()
    }
  }, [data?.levelSelect])

  const init = React.useCallback(async () => {
    console.log("initializing midi input")
    // await initializeMidiInput()
    // unfortunately this needs to happen here instead of in the 
    clearListeners()
    addListener(listener.current)

    if(!initialized && auth.jwtToken) {
      dispatch(appActions.setPlaySessionId(uuid()))
      // make sure level is not in a playing state (for pause button)
      dispatch(appActions.setLessonPlaying({lessonPlaying: false}))
      initSpacebarListener()
      scheduler2.printUuid()
      dispatch(appActions.updatePhrasesTilNextTier(4));
      dispatch(appActions.updateCurrentULP({
        currentUserLevelProgress: {
          status: LevelStatus.active,
        },
      }))

      // Initialize the first top and bottom phrases 
      if(auth.jwtToken) {
        if(data?.currentUserLevelData?.level?.level_number !== data?.levelData[levelSelect].level_number) {
          console.error("current user level data does not match level data")
          console.log("current user level data level number: " + data?.currentUserLevelData?.level?.level_number)
          console.log("level select level number: " + data?.levelData[levelSelect].level_number)
        }
        // await getAtLeastNMeasures(8, currentTier);
        const tiers = await getTiersForLevel();
        if(tiers.length) {
          dispatch(appActions.updateCurrentLevelTiers(tiers.length))
        }
        // If user level data is undefined an action is kicked off to create new ULP for the user,
        // which kicks off the reset function and re-pulls phrases. So, no need to do it here.
        if(data.currentUserLevelData && musicXMLStream.length() === 0) {
          await Promise.all([
            getNextNMeasures(measuresPerPhrase, data?.currentUserLevelData?.current_tier || 1),
            getNextNMeasures(measuresPerPhrase, data?.currentUserLevelData?.current_tier || 1)
          ])
        } else {
          if(!data.currentUserLevelData) {
            console.error("no user level data available to pull tiers")
          } 
        }
      } else {
        console.error("no jwt token available to pull tiers")
      }
      lockWakeState()
      setInitialized(true)
    }

  }, [currentTier, dispatch, getAtLeastNMeasures, getTiersForLevel, initialized, scheduler2, updatePhraseParts, auth.jwtToken])

  React.useEffect(() => {init() }, [])

  React.useEffect(() => {
    if(data.phrasesTilNextTier === 0) {
      setStarted(false)
      onStop();
      let now = new Date().toISOString();
      const ulpPatch: Partial<UserLevelData> = {}
      if(!data?.currentUserLevelData?.highest_tier_complete || 
        currentTier > data?.currentUserLevelData?.highest_tier_complete) {
          ulpPatch['highest_tier_complete'] = currentTier
          ulpPatch['highest_tier_completed_at'] = now
      }
      if(!data?.currentUserLevelData?.level_completed_at && currentTier >= data.currentLevelTiers) {
        ulpPatch['level_completed_at'] = now
      }
      if(currentTier >= data.currentLevelTiers) { // This is bugged somehow? All my levels have a date for level_completed_at. but still active.. (or maybe that's intentional if playing again; changed highet level complete to be set based on level_completed_at)
        ulpPatch['status'] = LevelStatus.complete
      }
      if(Object.keys(ulpPatch).length) {
        dispatch(appActions.updateCurrentULP({
          currentUserLevelProgress: ulpPatch,
        }))
      }
      sendPlayTimeInterval()
      accumulatedTimeRef.current = 0; // should start over between tier/level sessions
    }
    
  }, [currentTier, data.currentLevelTiers, data?.currentUserLevelData?.highest_tier_complete, data?.currentUserLevelData?.level_completed_at, data.phrasesTilNextTier, dispatch, onStop])
  

  React.useEffect(()=> {
    if(initialized) {
      reset()
    }
  }, [data.resetLessonUuid])

  useComponentWillUnmount(()=>{
    removeListener(listener.current)
    if(keyCallbackRef?.current) {
      document.removeEventListener('keyup', keyCallbackRef?.current)
    }
    if(process.env.REACT_APP_CURSOR_TIMEOUT) {
      clearTimeout(lastPlayedNoteTimer.current)
    }
    setInitialized(false)
    onStop()
    dispatch(appActions.setLessonPlaying({lessonPlaying: false}))
    midiStream.reset()
    musicXMLStream.reset()
    scheduler2?.reset()
    scheduler2?.cleatPauseUnpauseCallbacks()
    // midiAccess?.inputs.map(input => input.disconnec
    // terminate(scheduler2)
    // scheduler = undefined;
  })

  return (
      <div className="App"
        style = {{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          //minHeight: '600px',
          //height: '100vh',
          width: '100vw',
          background: 'white'
          //minWidth: '65rem' // will need breakpoints here eventually. 
        }}
      >
        {
          musicLoaded &&
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: 'center',
                // marginTop: '50px',
                marginTop: '50px',
                // marginBottom: '-100px'
                // marginBottom: '-150px'
                marginBottom: '0px',
                // backgroundColor: 'red',
                // height: '200px',
                // width: '200px',
              }}
            >
            {/* {
              (false && process.env.REACT_APP_NODE_ENV === 'dev' || process.env.REACT_APP_NODE_ENV === 'local') && 
              <>
                <Input 
                  type="file"
                  disableUnderline={true}    
                  onChange={handleFileUpload}
                  sx={{
                  }}
                />
              </>
            } */}
            </Box>

            <Staff
              scheduler={scheduler2}
              timeKeeper={scheduler2.timeKeeper}
              topPhraseStartTimestamp={topPhraseStartTimestamp}
              bottomPhraseStartTimestamp={bottomPhraseStartTimestamp}
              midiStream={midiStream}
              phrasePart0={phraseParts.get(0)}
              phrasePart1={phraseParts.get(1)}
              phrasePart2={phraseParts.get(2)}
              phrasePart3={phraseParts.get(3)}
              updateKey={updateKey}
              cursorIsVisible={cursorIsVisible}
            />

          </>
        }
        {
          !musicLoaded && 
          <CustomCircularProgress/>
        }
      </div>
  
  );
}

export default MainApp