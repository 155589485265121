import { useEffect } from 'react'
import { Box } from '@mui/material';    
import RoadmapPath from '../../assets/images/RoadmapPath.png';
import RoadmapPathComplete from '../../assets/images/RoadmapPathComplete.png';
import { useDispatch, useSelector } from 'react-redux'
import * as appActions from '../../Actions/app'
import { useRef, useState } from "react"
import { LevelData, LevelStatus, MainAppReducer, UserLevelData } from '../../Types'
import {find} from 'lodash'
import '../../index.css'
import './Path.css'
import { useNavigate } from 'react-router-dom';
import { AuthReducer } from 'Types/AuthTypes';
import { mainAppReducer } from 'Reducers/mainAppReducer';

interface LevelDataProps {
  left: string;
  top: string;
  levelName: string;
  levelNumber: number
  levelIndex: number;
  levelStatus: string;
  labelSide: string;
  isAccessible: boolean;
  highestTierComplete: number
}
interface LevelBlobProps {
  lastLevel: number;
  levelSelect: number;
  data: LevelDataProps;
  levelCompletedAt:string | undefined;
}

const smallScreenBreakWidth = 1200
const smallScreenBreakHeight = 900

const LevelBlob = (props: LevelBlobProps) => {
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const navigate = useNavigate();
  // LevelStatus should have one of 3 values: "not-started", "in-progress", "complete"
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);
  const { levelSelect, levelCompletedAt } = props
  let p = props.data
  let pos = {left: p.left, top: p.top}
  let complete = p.levelStatus === LevelStatus.complete//p.complete
  let inProgress = p.levelStatus === LevelStatus.active
  let bgColor = colors.background.incomplete// = complete ? colors.background.complete : colors.background.incomplete
  let chosenStyles = {}
  if (levelCompletedAt) {
    bgColor = colors.background.complete
    chosenStyles = completeContainerStyles
  } else if (inProgress) {
    bgColor = colors.background.inProgress
    chosenStyles = inProgressContainerStyles
  }
  let color = complete ? colors.color.complete : colors.color.incomplete
  const Label = () => {
    return (
      <Box sx={{...textStyles, ...{color: colors.color.incomplete}}}>
        <Box sx={{whiteSpace: 'nowrap'}}>{p.levelName}</Box>
      </Box>
    )
  }
  const dispatch = useDispatch()


  const handleSingleClick = (lNum: number) => {
    dispatch(appActions.setLevelSelect({ levelSelect: lNum }));
  };

  const handleDoubleClick = (lNum: number) => {
    dispatch(appActions.setLevelSelect({ levelSelect: lNum }));
    navigate(`/tutorial`)
  };

  const handleLevelSelect = (lNum: number) => {
    // we check here for single or double:
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleDoubleClick(lNum);
    } else {
      const newTimeout = setTimeout(() => {
        handleSingleClick(lNum);
        setClickTimeout(null);
      }, 200); // double click delay
      setClickTimeout(newTimeout);
    }
  };

  const maxLevelIndex = props.lastLevel-1;
  const minLevelIndex = 0;
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        if (levelSelect < maxLevelIndex) {
          dispatch(appActions.setLevelSelect({ levelSelect: levelSelect+1 }));
        }
      } else if (event.key === 'ArrowLeft') {
        if (levelSelect > minLevelIndex) {
          dispatch(appActions.setLevelSelect({ levelSelect: levelSelect-1 }));
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [levelSelect, dispatch, maxLevelIndex, minLevelIndex]);


  return (
    <Box sx={{...nameContainerStyles, ...pos}} onClick={() => handleLevelSelect(p.levelIndex)}>
      {(p.labelSide == "top") && <Label/> }
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
      <Box sx={{...circleContainerStyles, ...chosenStyles, position: 'relative',}}>
        <Box sx={{...numberStyles, ...{color: color}}}>{p.levelNumber}</Box>
        { p.levelIndex == levelSelect && <Box sx={selectedStyles}/>}
      </Box>
      </Box>
      {(p.labelSide == "bottom") && <Label/> }
    </Box>
  )
}

interface PathProps {
  lastLevelComplete: number;
  userLevelStatuses: any;
  scrollPos: any;
  setScrollPos: any;
  setScrollAreaSize: any;
  levelSelect: number;
  unitSelect: number;
  levelData: LevelData[];
  userLevelTiersComplete: any;
  areaToPreventScroll: string;
  setAreaToPreventScroll: any;
  pathScrollPos: any; //remove
  userLevelData: UserLevelData[];
  latestLevelRef: any
}

export const Path = (props: PathProps) => {
  const { 
    levelSelect,
    levelData,
    unitSelect,
    lastLevelComplete,
    userLevelStatuses,
    userLevelTiersComplete,
    areaToPreventScroll, setAreaToPreventScroll,
    pathScrollPos,
    userLevelData,
    latestLevelRef,
  } = props
  
  // Get the last complete level:
  const latestLevelRefContainer: any = useRef(null)
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight);
    useEffect(() => {
      const handleResize = () => { setIsSmallScreen(window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight); }
      window.addEventListener('resize', handleResize);
      return () => { window.removeEventListener('resize', handleResize); };
    }, []);

  const [blobPositions, pathPositions, basePxArray, xBlobPosVals, pathCompleteVals] = constGeneratePositions(isSmallScreen)
  
  // for where the roadmap path stops & provides value for scroll location
  const curLevelPosition = (xBlobPosVals[xBlobPosVals.length-1]+60)*(Math.floor(lastLevelComplete/xBlobPosVals.length))+xBlobPosVals[lastLevelComplete%xBlobPosVals.length] + 60
  
  useEffect(() => {
    if (latestLevelRef.current && areaToPreventScroll=='path') {
      latestLevelRef.current.scrollLeft = pathScrollPos
    }
  }, [pathScrollPos])

  useEffect(() =>{
    if (latestLevelRef.current) {
      setTimeout(() => {
        if (curLevelPosition) {
          latestLevelRef.current.scrollTo({left: curLevelPosition - 400, behavior: 'smooth'})
        }
      }, 250)

      props.setScrollPos(latestLevelRef.current.scrollLeft/(latestLevelRef.current.scrollWidth - latestLevelRef.current.clientWidth))
      props.setScrollAreaSize(latestLevelRef.current.clientWidth/latestLevelRef.current.scrollWidth)
    }
  }, [])

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (areaToPreventScroll=='minimap') {
        props.setScrollPos(e.target.scrollLeft/(e.target.scrollWidth - e.target.clientWidth))
      }
      props.setScrollAreaSize(e.target.clientWidth/e.target.scrollWidth)
    }
    if (latestLevelRef.current) {
      latestLevelRef.current.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (latestLevelRef.current) {
        latestLevelRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [areaToPreventScroll])

  useEffect(() => {
    if (latestLevelRef.current) {
      const handleDragStart = (e: any) => e.preventDefault();
      latestLevelRef.current.addEventListener("dragstart", handleDragStart);
      return () => {
        if (latestLevelRef.current) {
          latestLevelRef.current.removeEventListener("dragstart", handleDragStart);
        }
      };
    }
  }, []);

  useEffect(() => {
    const preventNavigation = (e: any) => {
      // console.log("scroll roadmap:", e.deltaX, e.target.scrollLeft, latestLevelRef.current.scrollLeft)
    //   if scrolled to the left, and scrolling left, prevent the default behavior to nav back (very annoying)
      if (latestLevelRef.current) {
        if (e.deltaX < 0 && e.target.scrollLeft <= 0 && latestLevelRef.current.scrollLeft <= 0) {
          e.preventDefault();
        }
      }
    }
    if (latestLevelRef.current) {
      latestLevelRef.current.addEventListener('wheel', preventNavigation, { passive: false });
      return () => {
        if (latestLevelRef.current) {
          latestLevelRef.current.removeEventListener('wheel', preventNavigation);
        }
      }
    }
  }, [])

  //let lastLevel = parseInt(Object.keys(Levels)[Object.keys(Levels).length -1])
  let lastLevel = levelData.length
  const numImagesShow = [...Array(Math.floor(lastLevel/12)+1)] // 12 levels can fit on one road map image, but need to load next image piece if on level 12.
  // ^^ Need to convert that math value into an array range!
  // Determin where to cut off the combined roadmap images based on where the last level lies!
  let blobLevelCutoff
  let numPaths = Math.floor(lastLevel/xBlobPosVals.length)
  let builtBlobPositions: any
  let endOffset = isSmallScreen ? 20 : 60
  if (numPaths == 0) {
    blobLevelCutoff = `${xBlobPosVals[xBlobPosVals.length-1]+50}px`
    builtBlobPositions = blobPositions
  } else {
    blobLevelCutoff = `${xBlobPosVals[xBlobPosVals.length-1]*(numPaths) + xBlobPosVals[lastLevel%xBlobPosVals.length]+numPaths*50 - endOffset}px`
    builtBlobPositions = blobPositions
    let foo = [...Array(numPaths)].map((k,v) => {
      let currentBasePxArray = basePxArray
      if (v == numPaths-1) {
        currentBasePxArray= {
          x: basePxArray.x.slice(0,lastLevel%xBlobPosVals.length),
          y: basePxArray.y.slice(0,lastLevel%xBlobPosVals.length)
        }
      }
      let newBlobPositions = convertToPos(currentBasePxArray, (v+1)*xBlobPosVals[xBlobPosVals.length-1]+(v+1)*60)
      builtBlobPositions = builtBlobPositions.concat(newBlobPositions)
    })
  }

  // Functions that allow clicking and dragging the mouse
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };
  const handleMouseMove = (e: any) => {
    if (areaToPreventScroll!='minimap') {
      setAreaToPreventScroll('minimap')
    }
    if (isDragging) {
      const dx = startX - e.clientX;
      latestLevelRef.current.scrollLeft += dx;
      setStartX(e.clientX);
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  let marginLeftRoadmap = 40
  return (
    <Box sx={{
      position: 'absolute', 
      left: `${marginLeftRoadmap}px`, // margin for roadmap
      right: '0px',
    }}>
      <Box ref={latestLevelRefContainer}>
        <Box className="chatbox-scroll-container" style={{
            // overflow: 'auto', 
            width: '100%', position: 'relative', paddingBottom: '50px', paddingTop: '50px', //keep at 50px, 50px for all sizes
            //cursor: isDragging ? "grabbing" : "grab",
            overflowX: 'auto', 
            overflowY: 'hidden',
          }} 
          ref={latestLevelRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          >
          <Box sx={{position: 'absolute'}}>
            { builtBlobPositions.map((e: any, i: any) => {
              let levelNum: number = unitSelect === 2 && levelData.length >= (i+1) + 26 ? (i+1) + 26 : (i+1)
              let levelStatus = userLevelStatuses[levelNum] ? userLevelStatuses[levelNum] : 'inactive'
              let highestTierComplete = userLevelTiersComplete[levelNum] ? userLevelTiersComplete[levelNum] : 0
              let isAccessible = false
              let levelNumber
              let levelName
              let levelCompletedAt = find(userLevelData, (datum:UserLevelData) => datum.level.level_number === levelNum)?.level_completed_at
              if (levelData[i]) {
                levelNumber = levelData[i].level_number
                levelName = levelData[i].name
                isAccessible = true
              } else {
                return (<Box key={`blob-${i}`} ref={latestLevelRef}></Box>)
              }
              return (
                <Box key={`blob-${i}`} ref={latestLevelRef}>
                  <LevelBlob lastLevel={lastLevel} levelCompletedAt={levelCompletedAt} levelSelect={levelSelect} data={{...e, levelIndex: i, levelNumber: levelNumber, levelName: levelName, levelStatus: levelStatus, highestTierComplete: highestTierComplete, isAccessible: isAccessible}}/>
                </Box>
              )
            })}
          </Box>
          
          <Box sx={{
            width: blobLevelCutoff,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            marginRight: '100px',
          }}>
            <Box sx={{display: 'flex', width: blobLevelCutoff, overflow: 'hidden', position: 'relative'}}>
              { numImagesShow.map((k,v) => {
                return <Box key={`img-${v}`}>
                  <img className="roadmap-img-styles" src={RoadmapPath}/>
                </Box>
              })}

              <Box sx={{position: 'absolute', width: `${curLevelPosition}px`, overflow: 'hidden', display: 'flex', }}>
              { numImagesShow.map((k,v) => {
                return <Box key={`img-complete-${v}`}>
                  <img className="roadmap-img-styles" src={RoadmapPathComplete}/>
                </Box>
              })}
              </Box>

            </Box>

            { unitSelect == 1 &&
              <Box>
                <Box sx={{
                  background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.68) 65.21%)',
                  position: 'absolute', top: '-300px', bottom: '-300px', width: '400px',
                  display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                  <Box sx={{ color: '#CED0D4', textAlign: 'center', fontFamily: 'Lato', fontSize: '24px', fonttyle: 'normal', fontWeight: '400', lineHeight: 'normal', letterSpacing: '0.24px' }}>
                    Unit 2 Coming Soon!
                  </Box>
                </Box>
              </Box>
            }

          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// Things needed for the roadmap... make it continuous for all levels
// Roadmap is fixed png, so can generate the positions in a cyclical maner
// The path position should just be a function of that

const constGeneratePositions = (isSmallScreen: any) => {
  
  let basePxArray = {
    x: [92, 265, 422, 576, 750, 886, 1022, 1173, 1320, 1460, 1620, 1766     ], // This will continue as fixed values until the loop pattern repeasts
    y: [305, 10,  212, -60, 305, 55,  368, -37,  280,  15,    310, 110,     ], // Same as above
  }

  if (isSmallScreen) {
    const heightReductionFactor = .65; // Calculate the scaling factor
    basePxArray = {
      x: basePxArray.x.map((value) => value * heightReductionFactor - 23), // Scale down x values
      y: basePxArray.y.map((value) => value * heightReductionFactor - 17), // Scale down y values
    };
  }

  const pathOffsetVAlue = 35
  const pathCompleteVals = basePxArray.x.map(v => {
    return v + pathOffsetVAlue
  })
  // Now convert all of this to useful styles
  const blobPositions = convertToPos(basePxArray)
  const pathPositions = pathCompleteVals.map(v => {
    let foo:any = {}
    foo.width = `${v}px`
    return foo
  })
  const xBlobPosVals = basePxArray.x
  return [blobPositions, pathPositions, basePxArray, xBlobPosVals, pathCompleteVals]
}

const convertToPos = (basePxArray: any, offset=0) => {
  const blobPositions = basePxArray.x.map((e: any, i: number) => {
    let labelSide = i%2 == 0 ? 'bottom': 'top' //label side will flip flop inperpetuity
    return ({
      left: `${basePxArray.x[i]+offset}px`,
      top: `${basePxArray.y[i]}px`,
      labelSide: labelSide
    })
  })
  return blobPositions
}

export const colors = {
  background: {
      complete: {backgroundColor: '#28856F'},
      inProgress: {backgroundColor: '#414753'},
      incomplete: {backgroundColor: '#5B646E'},
  },
  color:  {
    complete: {color: '#414753'},
    incomplete: {color: '#CED0D4'},
    selected: {
      background: "#CA7C62",
      cursor: 'pointer',
      transition: '0.3s',
      boxShadow: '0px 0px 5px 5px rgb(202,124,98,.8)',
    }
  }
}
const nameContainerStyles = {
  position: 'absolute',
  zIndex: 11,
}
export const circleContainerStyles = {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.background.incomplete,
  '&:hover': {
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(70, 159, 106,.5)',
  },
}
export const inProgressContainerStyles = {
  backgroundColor: colors.background.inProgress,
  border: '5px solid #28856F',
  boxSizing: 'border-box',
  '&:hover': {
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(70, 159, 106,.5)',
  },
}
export const completeContainerStyles = {
  backgroundColor: colors.background.complete,
  '&:hover': {
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(70, 159, 106,.5)',
  },
}
export const selectedStyles = {
  width: '62px', height: '62px',
  borderRadius: '50%',
  backgroundColor: 'rgba(70, 159, 106, 0.3)',
  border: '1px solid rgba(70, 159, 106, .8)',
  position: 'absolute',
  zIndex: -1,
}
export const numberStyles = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '20px',
}
const textStyles = {
  paddingTop: '10px',
  paddingBottom: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  width: '120px',
  display: 'flex',
  justifyContent: 'center'
}
