import { OpenSheetMusicDisplay as OSMD, GraphicalNote, VexFlowGraphicalNote, Note } from 'opensheetmusicdisplay';
import { PrerenderedGraphics, MidiEventState } from 'Types';
import { correctColor, mistimedColor, wrongColor, makeNoteWrong, makeNoteMistimed, makeNoteCorrect, resetNote } from 'Utils/OSMDUtility';
import { VoiceEntry } from 'opensheetmusicdisplay';
import ITimeKeeper from './ITimeKeeper';
import { findAndReplace, sumToN } from 'Utils';
import { sum, range, every, find, rest } from 'lodash';
import { v4 as uuid } from 'uuid';
import Phrase, {Iterator} from './Phrase';

const onsetDefault = 0.3
const offsetDefault = 0.3


// All timing configuration settings are percentages and relative to the length of the note/rest being played.
// For instance, a quarter note with a correct start onset allowance of 0.15 will be considered in the green zone
// as long as the MIDI onset event is received within 15% of the quarter note's duration at the quarter note's expected onset.
export class TimingOffsetsConfig {
    // Left margin percentage around a note's onset for the correct or green zone.
    correctNoteOnsetStartPct = onsetDefault * .5
    // Right margin percentage around a note's onset for the correct or green zone.
    correctNoteOnsetEndPct = .2
    // Left margin percentage around a note's onset for the mistimed or yellow zone.
    mistimedNoteOnsetStartPct = .35
    // Right margin percentage around a note's onset for the mistimed or yellow zone.
    mistimedNoteOnsetEndPct = 0.6
    
    // Left margin percentage around a note's offset for the correct or green zone.
    correctNoteOffsetStartPct = offsetDefault
    // Right margin percentage around a note's offset for the correct or green zone.
    correctNoteOffsetEndPct = offsetDefault
    // Left margin percentage around a note's offset for the mistimed or yellow zone.
    mistimedNoteOffsetStartPct = 0.35
    // Right margin percentage around a note's offset for the mistimed or yellow zone.
    mistimedNoteOffsetEndPct = 0.25
    
    // The margin percentages of rests SHRINK their bucket rather than growing it.

    // Left margin correct percentage around the start of the rest.
    // The correct zone shrinks "inward" (past the rest's onset) by this percentage.
    correctRestOnsetPct = 0.1
    // Left margin mistimed percentage around the start of the rest.
    // The mistimed zone shrinks "inward" (past the rest's onset) by this percentage.
    mistimedRestOnsetPct = 0.2
    // Right margin correcct percentage around the end of the rest.
    // The correct zone shrinks "inward" (before the rest's offset) by this percentage.
    correctRestOffsetPct = 0.25
    // Right margin mistimed percentage around the end of the rest.
    // The mistimed zone shrinks "inward" (before the rest's offset) by this percentage.
    mistimedRestOffsetPct = 0.25

}

// Timing offsets are relative to the expected timing for note on/off events.
export class TimingOffsets {
    // The max allowable timestamp ahead of the expected event time
    // for an event to be considered correctly timed in the correct or in the "green zone".
    correctStartTimestamp: number
    // The max allowable timestamp after the expected event time
    // for an event to be considered correctly timed in the correct or in the "green zone".
    correctEndTimestamp: number
    // The max allowable timestamp ahead of the expected event time
    // for an event to be considered mistimed or in the "yellow zone".
    mistimedStartTimestamp: number
    // The max allowable timestamp after the expected event time
    // for an event to be considered mistimed or in the "yellow zone".
    mistimedEndTimestamp: number

    constructor(correctStartTimestamp: number, correctEndTimestamp: number, mistimedStartTimestamp: number, mistimedEndTimestamp: number) {
        this.correctStartTimestamp = correctStartTimestamp
        this.correctEndTimestamp = correctEndTimestamp
        this.mistimedStartTimestamp = mistimedStartTimestamp
        this.mistimedEndTimestamp = mistimedEndTimestamp
    }
}

// Encapsulates everything we need to know about a single note or rest to match MIDI events to it.
// References OSMD graphical notes so we can handle coloring.
export class NoteEvent {
    length: number
    timestamp: number
    midiNote: number
    noteOnTimingOffsets: TimingOffsets
    noteOffTimingOffsets: TimingOffsets
    graphics: PrerenderedGraphics[]
    onsetState: MidiEventState = MidiEventState.UNSET
    offsetState: MidiEventState = MidiEventState.UNSET
    staffPosition: string

    constructor(
        timestamp: number, 
        midiNote: number,
        noteOnTimingOffsets: TimingOffsets, 
        noteOffTimingOffsets: TimingOffsets,
        graphics: PrerenderedGraphics[],
        length: number,
        staffPosition: string,
    ) {
        this.timestamp = timestamp
        this.midiNote = midiNote
        this.noteOnTimingOffsets = noteOnTimingOffsets
        this.noteOffTimingOffsets = noteOffTimingOffsets
        this.graphics = graphics
        this.length = length
        this.staffPosition = staffPosition
    }
}

export class RestEvent {
    length: number
    timestamp: number
    // Rests only have one set of offsets since they have no associated on/off MIDI events.
    timingOffsets: TimingOffsets
    state: MidiEventState = MidiEventState.UNSET
    graphics: PrerenderedGraphics[]
    hidden: boolean

    constructor(timestamp: number, timingOffsets: TimingOffsets, graphics: PrerenderedGraphics[], length: number, hidden: boolean) {
        this.timestamp = timestamp
        this.timingOffsets = timingOffsets
        this.graphics = graphics
        this.length = length
        this.hidden = hidden
    }
}

class EventBucket {
    // Any audible notes that are played at this timestamp.
    notes: NoteEvent[]

    // We process rests differently from notes;
    // they get their own list to keep things cleaner.
    rests: RestEvent[]

    // The exact number of timestamp that events in this bucket onset at.
    // Per-note timing offsets are added and subtracted from this number.
    timestamp: number

    // The earliest timestamp that a MIDI event could match on a note/rest in this bucket.
    // Before this time has passed, there's no reason to look inside this bucket when matching MIDI note ON events.
    minOnsetTimestamp: number = -1

    // The latest timestamp that a MIDI event could match on a note/rest in this bucket.
    // After this time has passed, there's no reason to look inside this bucket when matching MIDI note ON events.
    maxOnsetTimestamp: number = -1

    // After this timestamp has passed and we aren't waiting for any more offset events,
    // the playback data from this bucket can be uploaded to the backend.
    maxOffsetTimestamp: number = -1

    constructor(timestamp: number, notes: NoteEvent[], rests: RestEvent[]) {
        this.timestamp = timestamp
        this.notes = notes
        this.rests = rests

        this.minOnsetTimestamp = 0
        this.maxOnsetTimestamp = 0
        this.maxOffsetTimestamp = 0
        for (let note of notes) {
            if(note.noteOnTimingOffsets.mistimedStartTimestamp < this.minOnsetTimestamp ) {
                this.minOnsetTimestamp = note.noteOnTimingOffsets.mistimedStartTimestamp
            }
            // will probably need to revisit this
            if(note.noteOnTimingOffsets.mistimedEndTimestamp > this.maxOnsetTimestamp ) {
                this.maxOnsetTimestamp = note.noteOnTimingOffsets.mistimedEndTimestamp
            }
            if(note.noteOnTimingOffsets.mistimedEndTimestamp > this.maxOffsetTimestamp ) {
                this.maxOffsetTimestamp = note.noteOnTimingOffsets.mistimedEndTimestamp
            }
        }
    }

    addEvents(notes: NoteEvent[], rests: RestEvent[]) {
        this.notes.push(...notes)
        this.rests.push(...rests)
    }

    sort() {
        this.notes.sort((n1, n2) => n1.noteOffTimingOffsets.mistimedEndTimestamp - n2.noteOffTimingOffsets.mistimedEndTimestamp);
        this.rests.sort((r1, r2) => r1.timingOffsets.correctEndTimestamp - r2.timingOffsets.correctEndTimestamp)

        this.minOnsetTimestamp = Math.min(
            ...this.notes.map(n => n.noteOnTimingOffsets.mistimedStartTimestamp),
            ...this.rests.map(r => r.timingOffsets.correctStartTimestamp)
        )
        this.maxOnsetTimestamp = Math.max(
            ...this.notes.map(n => n.noteOnTimingOffsets.mistimedEndTimestamp),
            ...this.rests.map(r => r.timingOffsets.mistimedEndTimestamp)
        )
        this.maxOffsetTimestamp = Math.max(
            ...this.notes.map(n => n.noteOffTimingOffsets.mistimedEndTimestamp),
            ...this.rests.map(r => r.timingOffsets.correctEndTimestamp)
        )
    }
}


export default class EventStream {
    timeKeeper: ITimeKeeper
    private offsetsConfig: TimingOffsetsConfig
    private eventBuckets: EventBucket[] = []
    private activeOnsetEvents: (NoteEvent | null)[]
    private lastProcessedTimestamp: number = -1
    private onsetIndex: number = 0
    private missedNotesIndex: number = 0
    private _lookbackWindowSize = 40;
    private _weightedAccuracyDivisions: number;
    private _weightedAccuracyHitVelocity: number;
    private _weightedAccuracyMissVelocity: number;

    private activeNoteOnEvents: number = 0
    private correctNoteMagnitude: number = 1
    private offNoteMagnitude: number = .8
    private missMagnitude: number = .3
    accuracyQueue: MidiEventState[] = [];
    id = uuid();
    private useRepertoireMarkAccuracy: boolean;
    public errorRecognitionActive: boolean = true;


    constructor(timeKeeper: ITimeKeeper, offsetsConfig: TimingOffsetsConfig, weightedAccuracyDivisions: number = 1, weightedAccuracyHitVelocity: number = 0.025, weightedAccuracyMissVelocity:number = .1, useRepertoireMarkAccuracy: boolean = false) {
        this.timeKeeper = timeKeeper;
        this.offsetsConfig = offsetsConfig
        this._weightedAccuracyDivisions = weightedAccuracyDivisions;
        this._weightedAccuracyHitVelocity = weightedAccuracyHitVelocity;
        this._weightedAccuracyMissVelocity = weightedAccuracyMissVelocity;
        this.useRepertoireMarkAccuracy = useRepertoireMarkAccuracy; 
        if (this.useRepertoireMarkAccuracy) {
          this._lookbackWindowSize = 0;
        }

        this.activeOnsetEvents = []
        this.setupOnsetEvents()
        this.initAccuracyQueue()
    }

    // workaround because the midi context loses reference to state. So, turn off error recognition here.
    setErrorRecognitionActive(active:boolean) {
        this.errorRecognitionActive = false;
    }


    initAccuracyQueue = () => {
        this.accuracyQueue = range(this._lookbackWindowSize).map(() => MidiEventState.HIT)
    }

    accuracyQueueLength = () => this.accuracyQueue.length;

    totalNotesLength = () => {
      let totalNotesSum = 0;
      this.eventBuckets.forEach((el) => totalNotesSum += el.notes.length);
      return totalNotesSum
    };

    rollBackWindowFull = () => this.accuracyQueue.length >= this._lookbackWindowSize;

    // Mark state. If current state is anything but unset, that value is removed from the queue and replaced. 
    private markAccuracy(currentNoteState: MidiEventState, newNoteState:MidiEventState) {
      if(currentNoteState === newNoteState) {
        return;
      }
      switch(currentNoteState) {
        case MidiEventState.HIT:
        case MidiEventState.MISSED:
        case MidiEventState.OFF_TIME:
          findAndReplace(currentNoteState, newNoteState, this.accuracyQueue)
          break;
        default:
          this.accuracyQueue.push(newNoteState)
          if (!this.useRepertoireMarkAccuracy) { // Repertoire does not use lookback window and doesn't need to cut array
            if(this.accuracyQueue.length > this._lookbackWindowSize) {
                this.accuracyQueue.shift()
            }
          }
          break;  
      }
    }

    _constructAccuracyWeightsArr = (): number[] => {
        let weightedAccuracyDivisionsIndx = 0;
        return this.accuracyQueue.map((evt,indx) => {
            if(indx >  ((weightedAccuracyDivisionsIndx + 1) * this._weightedAccuracyDivisions) - 1) {
                weightedAccuracyDivisionsIndx = weightedAccuracyDivisionsIndx + 1;
            }
            switch(evt) {
                case(MidiEventState.HIT):
                    return 1 - (this._weightedAccuracyHitVelocity * weightedAccuracyDivisionsIndx)
                case(MidiEventState.OFF_TIME):
                    return 1 - (this._weightedAccuracyHitVelocity * weightedAccuracyDivisionsIndx)
                default:
                    return 1 - (this._weightedAccuracyMissVelocity * weightedAccuracyDivisionsIndx)
            }
        })
    }

    calcAccuracyV2() {
        const accuracyLength = this.accuracyQueue.length;
        const accuracyReversed = this.accuracyQueue.slice().reverse()
        const numerator = sum(accuracyReversed.map((evt, indx) => {
            let sumTarget
            switch(evt) {
                case(MidiEventState.HIT):
                    // sumTarget = (accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx) >= 0 ? ((accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx)) : 0
                    return (accuracyLength - indx)
                case(MidiEventState.OFF_TIME):
                    // start subtracting from top of magnitude... i.e. 40 * .5 is 20 so 20, 19, 18...
                    // and when we get to zero 
                    sumTarget = (accuracyLength - indx) * this.correctNoteMagnitude >= 0 ? (accuracyLength - indx) * this.correctNoteMagnitude : 0
                    return sumTarget * this.offNoteMagnitude
                default:
                    return 0
            }
        }))
        const denominator = sum(accuracyReversed.map((evt, indx) => {
            let sumTarget;
            switch(evt) {
                case(MidiEventState.HIT):
                    // sumTarget = (accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx) >= 0 ? ((accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx)) : 0
                    return (accuracyLength - indx)
                case(MidiEventState.OFF_TIME):
                    // start subtracting from top of magnitude... i.e. 40 * .5 is 20 so 20, 19, 18...
                    // and when we get to zero 
                    sumTarget = (accuracyLength - indx) * this.correctNoteMagnitude >= 0 ? (accuracyLength - indx) * this.correctNoteMagnitude : 0
                    return sumTarget
                default:
                    // same same but different
                    sumTarget = (accuracyLength - indx) * this.missMagnitude >= 0 ? (accuracyLength - indx) * this.missMagnitude : 0
                    return sumTarget
            }
        }))
        // console.log("returning accuracy " + (numerator / denominator) * 100)
        return (numerator / denominator) * 100
    }


    calcRepAccuracyAndCompletion(tempo: number, performanceTempo: number, setAccuracyAtTempo: React.Dispatch<React.SetStateAction<number[]>>) {
      // I had to bring this logic of "setAccuracyAtTempo" inside the midi stream, because in the context
      // "midiStream.accuracyQueue" does not update unless some other action causes re-render
      //  (you can observe by logging "midiStream.accuracyQueue" in the RepertoirePlayContext vs logging "this.accuracyQueue" here in EventStream)
    
      const accuracyLength = this.accuracyQueue.length;
        const accuracyReversed = this.accuracyQueue.slice().reverse()
        const numerator = sum(accuracyReversed.map((evt, indx) => {
            switch(evt) {
              case(MidiEventState.HIT):
                return 1
              case(MidiEventState.OFF_TIME):
                return 0.50
              default:
                return 0
            }
        }))
        const denominator = accuracyLength
        let newVal = this.accuracyQueue.map((v) => {
          let performanceFac = Math.min(tempo/performanceTempo, 1)
          if (v == MidiEventState.MISSED) {
            return 0*performanceFac
          } else if (v == MidiEventState.OFF_TIME) {
            return 0.5*performanceFac
          } else {
            return 1.0*performanceFac
          }
        })
        setAccuracyAtTempo(newVal);
        return (numerator / denominator) * 100
    }

    private setupOnsetEvents() {
        // There are 128 MIDI event pitches.
        // Allocate a fixed size array with 128 slots,
        // and incoming MIDI on/off events will be "mapped" using their pitch into this array.
        this.activeOnsetEvents = new Array(128)
        this.activeOnsetEvents.fill(null)
        // See: https://stackoverflow.com/a/44853951
        Object.seal(this.activeOnsetEvents)

        let str = "" 
        for (let i = 0; i <= 24; i++) {
            const x = (1/24) * i
            str = str + `\n${4*x}, ${4*this.calculateCorrectNoteOffMin(x)}`
        }
    }

    reset() {
        this.eventBuckets = []
        this.lastProcessedTimestamp = -1
        this.onsetIndex = 0
        this.missedNotesIndex = 0
        this.setupOnsetEvents()
        this.initAccuracyQueue()
        // this.accuracyQueue = []
    }

    addEvents2(iterator: Iterator, startMeasure: number) {
        iterator.resetIterator()

        while(!iterator.EndReached) {

            const notes: NoteEvent[] = []
            const rests: RestEvent[] = []
            const onsetTimestamp = startMeasure + iterator.currentTimeStamp

            iterator.CurrentVoiceEntries.forEach(currentVoiceEntry => {
                for (const note of currentVoiceEntry.Notes) {
                    // todo: figure out how to do note-tie in prerendering 
                    const noteDuration = note.length
                    // calculateTiedNoteLength() will return a negative value 
                    // if a tied note should be discarded for event stream purposes.
                    // This is now performed in the prerenderer
                    if (noteDuration <= 0) {
                        return
                    }                    
                    const offsetTimestamp = onsetTimestamp + noteDuration
                    const correctNoteOffsetStartTimestamp = onsetTimestamp + this.calculateCorrectNoteOffMin(noteDuration)
    
                    const startAllowance = 0.25
                    const endAllowance = 0.25
    
                    if (note.isRest) {
                        const restEvent = new RestEvent(
                            onsetTimestamp,
                            new TimingOffsets(
                                onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.correctRestOnsetPct,
                                offsetTimestamp - endAllowance * this.offsetsConfig.correctRestOffsetPct,
                                onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.mistimedRestOnsetPct,
                                offsetTimestamp - endAllowance * this.offsetsConfig.mistimedRestOffsetPct
                            ),
                            note.graphics,
                            note.length,
                            !note.printObject
                        )
                        rests.push(restEvent)
                    } else {
                        const noteOnTimestamps = new TimingOffsets(
                            onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.correctNoteOnsetStartPct,
                            onsetTimestamp + endAllowance * this.offsetsConfig.correctNoteOnsetEndPct,
                            onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.mistimedNoteOnsetStartPct,
                            onsetTimestamp + endAllowance * this.offsetsConfig.mistimedNoteOnsetEndPct
                        )
    
                        const noteOffTimestamps = new TimingOffsets(
                            correctNoteOffsetStartTimestamp,
                            offsetTimestamp + endAllowance * this.offsetsConfig.correctNoteOffsetEndPct,
                            correctNoteOffsetStartTimestamp - noteDuration*0.2,
                            offsetTimestamp + endAllowance * this.offsetsConfig.mistimedNoteOffsetEndPct
                        )
    
                        const graphics:  PrerenderedGraphics[] = note.noteTie
                            ? note?.noteTie?.Notes.map((n: any) => n.graphics)
                            : note.graphics
                        const noteEvent = new NoteEvent(
                            onsetTimestamp, 
                            note.pitch.halfTone+12,
                            noteOnTimestamps, 
                            noteOffTimestamps,
                            graphics,
                            note.length,
                            note.staffPosition,
                        )
                        notes.push(noteEvent)

                }
                }
            });

            // Check if there's already a bucket for this timestamp.
            // OSMD's iterator WILL repeat timestamps.
            let bucket = this.eventBuckets.find(b => Math.abs(b.timestamp - onsetTimestamp) < Number.EPSILON)
            if (bucket === undefined) {
                bucket = new EventBucket(onsetTimestamp, notes, rests)
                this.eventBuckets.push(bucket)
            } else {
                bucket.addEvents(notes, rests)
            }

            iterator.next()
        }

        // Tell all the event buckets to sort their internals and then we'll sort the buckets themselves.
        this.eventBuckets.forEach(b => b.sort())
        this.eventBuckets.sort((b1, b2) => b1.minOnsetTimestamp - b2.minOnsetTimestamp)
    }

    // addEvents(osmd: OSMD, startMeasure: number) {
    //     // TODO I think we could get around this and just make a cursor if none exists.
    //     if(osmd.cursors.length < 1) {
    //         throw Error("osmd object passed into toMidiEvents without cursor");
    //     }

    //     // Construct our event buckets from the buckets that OSMD has already sorted for us.
    //     // We'll sort the full eventBuckets list after looping through and adding all the new note/rest events.
    //     const cursor = osmd.cursors[0]
    //     cursor.resetIterator()
    //     const iterator = cursor.Iterator
    //     while (!iterator.EndReached) {
    //         const onsetTimestamp = startMeasure + iterator.currentTimeStamp.RealValue
    //         const notes: NoteEvent[] = []
    //         const rests: RestEvent[] = []

    //         iterator.CurrentVoiceEntries.forEach((voiceEntry: VoiceEntry) => {
    //             voiceEntry.Notes.forEach((note: Note) => {
    //                 const noteDuration = note.NoteTie == null ? note.Length.RealValue : this.calculateTiedNoteLength(note)
    //                 // calculateTiedNoteLength() will return a negative value 
    //                 // if a tied note should be discarded for event stream purposes.
    //                 if (noteDuration <= 0) {
    //                     return
    //                 }
                    
    //                 const offsetTimestamp = onsetTimestamp + noteDuration
    //                 const correctNoteOffsetStartTimestamp = onsetTimestamp + this.calculateCorrectNoteOffMin(noteDuration)

    //                 const noteAllowance = 0.25
    //                 const graphics: GraphicalNote[] = note.NoteTie
    //                 ? note.NoteTie.Notes.map((n: Note) => osmd.EngravingRules.GNote(n))
    //                 : [ osmd.EngravingRules.GNote(note) ]

    //                 if (note.isRest()) {
    //                     console.log("adding rest")
    //                     rests.push(new RestEvent(
    //                         onsetTimestamp,
    //                         new TimingOffsets(
    //                             onsetTimestamp + noteAllowance * this.offsetsConfig.correctRestOnsetPct,
    //                             offsetTimestamp - noteAllowance * this.offsetsConfig.correctRestOffsetPct,
    //                             onsetTimestamp + noteAllowance * this.offsetsConfig.mistimedRestOnsetPct,
    //                             offsetTimestamp - noteAllowance * this.offsetsConfig.mistimedRestOffsetPct
    //                         ),
    //                         graphics
    //                     ))
    //                 } else {
    //                     const noteOnTimestamps = new TimingOffsets(
    //                         onsetTimestamp - noteAllowance * this.offsetsConfig.correctNoteOnsetStartPct,
    //                         onsetTimestamp + noteAllowance * this.offsetsConfig.correctNoteOnsetEndPct,
    //                         onsetTimestamp - noteAllowance * this.offsetsConfig.mistimedNoteOnsetStartPct,
    //                         onsetTimestamp + noteAllowance * this.offsetsConfig.mistimedNoteOnsetEndPct
    //                     )

    //                     const noteOffTimestamps = new TimingOffsets(
    //                         correctNoteOffsetStartTimestamp,
    //                         offsetTimestamp + noteAllowance * this.offsetsConfig.correctNoteOffsetEndPct,
    //                         correctNoteOffsetStartTimestamp - noteDuration*0.2,
    //                         offsetTimestamp + noteAllowance * this.offsetsConfig.mistimedNoteOffsetEndPct
    //                     )

    //                     notes.push(new NoteEvent(
    //                         onsetTimestamp, 
    //                         note.Pitch.getHalfTone()+12,
    //                         noteOnTimestamps, 
    //                         noteOffTimestamps,
    //                         graphics)
    //                     )
    //                 }
    //             })
    //         })

    //         // Check if there's already a bucket for this timestamp.
    //         // OSMD's iterator WILL repeat timestamps.
    //         let bucket = this.eventBuckets.find(b => Math.abs(b.timestamp - onsetTimestamp) < Number.EPSILON)
    //         if (bucket === undefined) {
    //             bucket = new EventBucket(onsetTimestamp, notes, rests)
    //             this.eventBuckets.push(bucket)
    //         } else {
    //             bucket.addEvents(notes, rests)
    //         }

    //         iterator.moveToNext()
    //     }

    //     // Tell all the event buckets to sort their internals and then we'll sort the buckets themselves.
    //     this.eventBuckets.forEach(b => b.sort())
    //     this.eventBuckets.sort((b1, b2) => b1.maxOnsetTimestamp - b2.maxOnsetTimestamp)
    //     this.eventBuckets.sort((b1, b2) => b1.minOnsetTimestamp - b2.minOnsetTimestamp)
    //     console.log(this.eventBuckets)
    // }

    handleNoteOnEvent(midiNote: number, velocity: number) {
        if(!this.errorRecognitionActive) {return}
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if(this.timeKeeper.isPaused()) {
            return
        }
        // Look for the played MIDI note, or a closest match, in each possible note bucket that we're in right now.
        let closestNoteMatch: NoteEvent | null = null
        let closestPitchDiff: number = -1
        let restMatches: RestEvent[] = []
        let possibleNotes: NoteEvent[] = []
        let exactMatchFound = false
        let possibleNoteBuckets: EventBucket[] = []
        let offTime = false
        let latestOnsetIndex = 0;
        for (let i = (this.onsetIndex > 0 ? this.onsetIndex - 1: this.onsetIndex) ; i < this.eventBuckets.length; i++) 
        {
            const bucket = this.eventBuckets[i]

            if (!this.isInBucket(timestamp, i)) {
                continue
            }
            else if (timestamp < bucket.minOnsetTimestamp) {
                break
            }
            latestOnsetIndex = i;
            possibleNotes = possibleNotes.concat(bucket.notes)
            possibleNoteBuckets = possibleNoteBuckets.concat(bucket);
            restMatches = restMatches.concat(bucket.rests);
            

            for (const noteEvent of bucket.notes) {
                // Skip this note if we're outside its time range.
                if (timestamp < noteEvent.noteOnTimingOffsets.mistimedStartTimestamp
                    || timestamp > noteEvent.noteOnTimingOffsets.mistimedEndTimestamp) {
                    continue
                }
              

                const pitchDiff = Math.abs(noteEvent.midiNote - midiNote)
                // Exact match found! We can bail out of the outer loop now.
                // but, if it's already set, skip to the next closest
                if (pitchDiff === 0 ) {
                    closestNoteMatch = noteEvent
                    exactMatchFound = true
                    closestPitchDiff = 0
                    if(timestamp < noteEvent.noteOnTimingOffsets.correctStartTimestamp || timestamp > noteEvent.noteOnTimingOffsets.correctEndTimestamp) {
                        offTime = true
                    }
                    // break
                }
                // Remember this note if it's the first note we've come across.
                else if (closestNoteMatch == null) {
                    closestNoteMatch = noteEvent
                    closestPitchDiff = pitchDiff
                }
                else {
                    // Remember this note if its pitch is equal or closer than the current closest match,
                    // and the note's timestamp is the closer to the current timestamp.
                    const closestMatchTimestampDiff = Math.abs(closestNoteMatch.timestamp - timestamp)
                    const timestampDiff = Math.abs(noteEvent.timestamp - timestamp)
                    if (closestPitchDiff >= pitchDiff && closestMatchTimestampDiff >= timestampDiff) {
                        closestNoteMatch = noteEvent
                        closestPitchDiff = pitchDiff
                    }
                }
            }

            // for (const rest of bucket.rests) {
            //     console.log("comparing timestamp: ", rest)
            //     if (timestamp < rest.timingOffsets.mistimedStartTimestamp
            //         || timestamp > rest.timingOffsets.mistimedEndTimestamp) {
            //         console.log("timestamp skipped")
            //         continue
            //     }
            //     restMatch = rest;
            //     console.log("Rest match: ", rest)
            // }

            // TODO look at rests!!
            // We should only match on a rest if there are no possible notes we could match against.
        }
        // we want the latest bucket to trump earliest 
        possibleNotes.sort((a, b) => b.timestamp - a.timestamp)
        
        // If an exact match was found, we know which note to color.
        possibleNoteBuckets.sort((eventBucket1, eventBucket2) => Math.abs(eventBucket1.timestamp - timestamp) - Math.abs(eventBucket2.timestamp - timestamp))
        // You shouldn't be able to change a note state after it's been set in offset state. However, we allow it in chords.
        // That means that it can happen if a note happens to be a chord. this fixes that 
        
        const isAttemptedNoteInChord = 
            (possibleNoteBuckets.length > 0 && (possibleNoteBuckets[0].notes.length > 1) )

        if(latestOnsetIndex >= this.onsetIndex || isAttemptedNoteInChord
            ) {
            if (closestNoteMatch && exactMatchFound && closestNoteMatch.offsetState === MidiEventState.UNSET) {
                const closestNoteBucket = possibleNoteBuckets[0] 
    
                const isChord = closestNoteBucket.notes.length > 1;
                // Are we in the note's correct zone?
                // We already checked earlier if we're in the mistimed zone, so we only need to check the correct zone now.
                if (closestNoteMatch.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp
                    && closestNoteMatch.noteOnTimingOffsets.mistimedEndTimestamp >= timestamp
                    // another check for whether the note's already been played through before pause set back
                    && closestNoteMatch.offsetState === MidiEventState.UNSET) 
                {
                    if(closestNoteMatch.onsetState === MidiEventState.UNSET ) {
                        if(!offTime) {
                            makeNoteCorrect(closestNoteMatch.graphics)
                            this.pulseMatchedNote(closestNoteMatch, 'correct')
                            this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.HIT)
                            closestNoteMatch.onsetState = MidiEventState.HIT
                        } else {
                            makeNoteMistimed(closestNoteMatch.graphics)
                            this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.OFF_TIME)
                            closestNoteMatch.onsetState = MidiEventState.OFF_TIME
                        }
                       
                        // if we're in a chord it's okay to change a missed to an off time
                        // however, when rewound after pause, it's impossible to tell the difference
                        // between a note that was missed due to a previous miss or a miss that just ocurred
                        // while attempting to play a chord. So, use the index discovered in the iteration above
                        // in comparison to the ever advancing onset index to check if we're in a re-wound state.
                        // bit janky but might work.
                    } else if ( 
                        closestNoteMatch.onsetState !== MidiEventState.MISSED ||
                               (closestNoteMatch.onsetState === MidiEventState.MISSED && isChord)) {

                        // should never go from missed to offtime
                        makeNoteMistimed(closestNoteMatch.graphics)
                        this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.OFF_TIME)
                        closestNoteMatch.onsetState = MidiEventState.OFF_TIME
                    }  else if(closestNoteMatch.onsetState !== MidiEventState.MISSED){
                        makeNoteMistimed(closestNoteMatch.graphics)
                        this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.OFF_TIME)
                        closestNoteMatch.onsetState = MidiEventState.OFF_TIME
                    }
                    this.activeOnsetEvents[closestNoteMatch.midiNote] = closestNoteMatch
                }
            } else if (
                (closestNoteMatch != null && possibleNotes.length == 1 && !(possibleNoteBuckets[0].rests.length > 0 && possibleNoteBuckets[0].notes.length === 0)) || 
                (closestNoteMatch == null && possibleNotes.length == 1 && !(possibleNoteBuckets[0].rests.length > 0 && possibleNoteBuckets[0].notes.length === 0))
                ) {
                if(closestNoteMatch === null) {
                    // not sure why there are situations where closest match is null and possible notes is 1, but not going to fix it right now
                    // Also check for rest in case closest note bucket is a rest
                    closestNoteMatch = possibleNotes[0]
                }
                // never set a note with an offset state
                if(closestNoteMatch.offsetState === MidiEventState.UNSET) {
                    // There's only one note in this time range we can match against. Color the note red.
                    if (closestNoteMatch.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp
                        && closestNoteMatch.noteOnTimingOffsets.mistimedEndTimestamp >= timestamp) 
                    {
                        if(closestNoteMatch.onsetState === MidiEventState.UNSET) {
                            makeNoteWrong(closestNoteMatch.graphics)
                            this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.MISSED)
                            closestNoteMatch.onsetState = MidiEventState.MISSED
                        } else if(closestNoteMatch.onsetState === MidiEventState.HIT) {
                            this.markAccuracy(closestNoteMatch.onsetState, MidiEventState.OFF_TIME)
                            makeNoteMistimed(closestNoteMatch.graphics)
                            closestNoteMatch.onsetState = MidiEventState.OFF_TIME
                        }
                        this.activeOnsetEvents[midiNote] = closestNoteMatch
                    }
                }
            } else if (possibleNotes.length > 1) {
                // at this point we know this note is extraneous (probably, unless it's an exact match that slipped through, but we look for that below) 
                // but we need to figure out which note to color in.
                // sort buckets by latest because we don't want to
                // sort by closeness to midi note
                possibleNotes.sort((note1, note2) => Math.abs(midiNote - note1.midiNote) - Math.abs(midiNote - note2.midiNote))
                let closestInBucket: NoteEvent | undefined = undefined;
    
                const allPlayed =  every(possibleNotes, note => note.onsetState !== MidiEventState.UNSET)
                if(allPlayed) {
                    // if everything's been hit we can just adjust the most recent note hit. 
                    // with the exception that we don't change missed to offtime, so find the first
                    // non missed
                    for(let i = 0; i < possibleNotes.length; i++) {
                        const currNote = possibleNotes[i];
                        const inTimespan = currNote.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp 
                            && currNote.noteOffTimingOffsets.correctStartTimestamp >= timestamp 
                        // There's a possibility that exact note isn't caught in the initial note matching logic above if it
                        // comes in after mistimed start time allowance. This exact note circuitbreaker catches that.
                        // In the case that the exact match was already set as missed - we don't want to continue looking
                        // for the next note to mark offtime. Instead, do nothing (if already missed) or mark offtime
                        exactMatchFound = currNote.midiNote === midiNote
                        if (inTimespan &&
                            (exactMatchFound ||
                            (currNote.onsetState !== MidiEventState.MISSED && currNote.offsetState === MidiEventState.UNSET))) {
                            closestInBucket = currNote
                            break;
                        }
                    }
                    if(closestInBucket) {
                        // if(isChord && closestInBucket.onsetState === MidiEventState.MISSED) {
                        //     console.log("marking note off time because mistimed end in note off event")
                        //     this.markAccuracy(closestInBucket.onsetState, MidiEventState.OFF_TIME)
                        //     makeNoteMistimed(closestInBucket.graphics)
                        //     closestInBucket.onsetState = MidiEventState.OFF_TIME
                        // }
                        if(closestInBucket.onsetState !== MidiEventState.MISSED && closestInBucket.offsetState === MidiEventState.UNSET) {
                            this.markAccuracy(closestInBucket.onsetState, MidiEventState.OFF_TIME)
                            makeNoteMistimed(closestInBucket.graphics)
                            closestInBucket.onsetState = MidiEventState.OFF_TIME
                        }
                        this.activeOnsetEvents[midiNote] = closestInBucket
                    }
                } else {
                    // if not every note's been played, instead look for the first non set note, or an exact match that fell
                    // through above and is therefore an offtime.
                    for(let i = 0; i < possibleNotes.length; i++) {
                        const currNote = possibleNotes[i];
                        const inTimespan = currNote.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp 
                            && currNote.noteOffTimingOffsets.correctStartTimestamp >= timestamp     
                            exactMatchFound = currNote.midiNote === midiNote
                        if (inTimespan && 
                            (exactMatchFound ||
                            (currNote.onsetState ===  MidiEventState.UNSET && currNote.offsetState === MidiEventState.UNSET))) {
                            closestInBucket = currNote
                            break;
                        } 
                    }
                    
                    if(closestInBucket && exactMatchFound && closestInBucket.offsetState === MidiEventState.UNSET) {
                        // this catches an exact match that fell through the initial note matching logic. The only 
                        // way to fall through the initial note matching logic above is by being outside the correct 
                        // start onset bucket - so we can just mark it offtime. It's okay if it's a miss, because
                        // we can change miss to off time within a chord
                        this.markAccuracy(closestInBucket.onsetState, MidiEventState.OFF_TIME)
                        makeNoteMistimed(closestInBucket.graphics)
                        closestInBucket.onsetState = MidiEventState.OFF_TIME
                        this.activeOnsetEvents[midiNote] = closestInBucket

                    } else if(closestInBucket && closestInBucket.onsetState !== MidiEventState.MISSED && closestInBucket.offsetState === MidiEventState.UNSET) {
                        // otherwise mark the first non set a miss
                        this.markAccuracy(closestInBucket.onsetState, MidiEventState.MISSED)
                        makeNoteWrong(closestInBucket.graphics)
                        closestInBucket.onsetState = MidiEventState.MISSED
                        this.activeOnsetEvents[midiNote] = closestInBucket

                    }
                }
            } else {
                // Do rest logic here, by now we've confirmed there are absolutely no notes in this time range.
                // Even though this is the same as the above conditional, we should  keep them separate in case
                // we wnt do something differently.
                restMatches.sort((rest1, rest2) => Math.abs(midiNote - rest1.timestamp) - Math.abs(midiNote - rest2.timestamp))
    
                let closestRestMatch: RestEvent | undefined = restMatches.length > 0 ? restMatches[0] : undefined
                for(let rest of restMatches ) {
                    if(rest.state === MidiEventState.UNSET &&
                        !rest.hidden &&
                        (!closestRestMatch || 
                            (closestRestMatch && 
                                (Math.abs(timestamp - rest.timestamp) < Math.abs(timestamp - closestRestMatch.timestamp))))
                    ) {
                        closestRestMatch = rest
                    }
                }
                if(closestRestMatch) {
                    this.markAccuracy(MidiEventState.UNSET, MidiEventState.OFF_TIME)
                    makeNoteMistimed(closestRestMatch.graphics);
                    closestRestMatch.state = MidiEventState.OFF_TIME
                }
            }
        }
    }

    handleNoteOffEvent(midiNote: number) {
        if(!this.errorRecognitionActive) {return}
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if(this.timeKeeper.isPaused()) {
            return
        }
        // Offset events are much easier to handle than onset events!
        // We already matched the onset event earlier and kept track of it. 
        // All we have to do now is re-color the note if an offset event was out of range.
        const match = this.activeOnsetEvents[midiNote]
        if (match != null && match.offsetState === MidiEventState.UNSET) {
            const timingOffsets = match.noteOffTimingOffsets
            // console.log("offset match for midiNote " + midiNote + " at timestamp " + timestamp + ", timing offsets are ("
            //     + timingOffsets.correctStartTimestamp  + ", " + timingOffsets.correctEndTimestamp + "), (" 
            //     + timingOffsets.mistimedStartTimestamp + ", " + timingOffsets.mistimedEndTimestamp);
            if(match.onsetState === MidiEventState.MISSED) {
                match.offsetState = MidiEventState.MISSED // if onset is missed, offset is missed
                makeNoteWrong(match.graphics)
            } else if (timestamp >= timingOffsets.correctStartTimestamp && timestamp <= timingOffsets.correctEndTimestamp) {
                // WOOHOO, they hit the correct zone
                // make sure not to over-write an offtime start
                if(match.onsetState !== MidiEventState.OFF_TIME) {
                    match.offsetState = MidiEventState.HIT
                    this.markAccuracy(match.onsetState, MidiEventState.HIT)
                    makeNoteCorrect(match.graphics)
                }
                // Here we might want to actually edit the accuracy queue to remove a miss and replace with a hit
            } else if (timestamp >= timingOffsets.mistimedStartTimestamp && timestamp <= timingOffsets.mistimedEndTimestamp) {
                makeNoteMistimed(match.graphics)
                this.markAccuracy(match.onsetState, MidiEventState.OFF_TIME)
                match.offsetState = MidiEventState.OFF_TIME
            } else {
                makeNoteMistimed(match.graphics)
                this.markAccuracy(match.onsetState, MidiEventState.OFF_TIME)
                // makeNoteRed(match.graphic)
                match.offsetState = MidiEventState.OFF_TIME
            }

            // Now that we've processed the note off event, we're no longer actively tracking this note,
            // and can unset it in the events array.
            this.activeOnsetEvents[midiNote] = null
        }
    } 

    checkAccuracyNoteHand(note: any, errorRecognitionActiveRight: any, errorRecognitionActiveLeft: any) {
      if (note != null) {
        const noteRightHand = note.staffPosition == 'top'
        if (noteRightHand && !errorRecognitionActiveRight) return false
        if (!noteRightHand && !errorRecognitionActiveLeft) return false
      }
      return true
    }

    // Should be called on a set interval. Looks for missed notes and colors them yellow/red.
    update(errorRecognitionActiveLeft: boolean = true, errorRecognitionActiveRight: boolean = true) {
        if(!this.errorRecognitionActive) {return}
        // Don't update if we re-wound and are counting back in.
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if (timestamp < this.lastProcessedTimestamp || this.missedNotesIndex >= this.eventBuckets.length) {
            return
        }
        this.lastProcessedTimestamp = timestamp
        
        // This was to stop all error recognition.
        // if (!errorRecognitionActiveLeft || !errorRecognitionActiveRight) {
        //   return
        // }

        // Look for notes in buckets that should be colored yellow or red.
        for (let i = this.onsetIndex; i < this.eventBuckets.length && this.isInBucket(timestamp, i); i++) {
            const bucket = this.eventBuckets[i]
            bucket.notes.forEach(note => {
                if (!this.checkAccuracyNoteHand(note, errorRecognitionActiveRight, errorRecognitionActiveLeft)) return

                // If an note on event has been received for this note, ignore it.
                if (note.onsetState != MidiEventState.UNSET) {
                    return
                }

                if (timestamp > note.noteOnTimingOffsets.mistimedEndTimestamp) {
                    // Mistimed window was missed, color error
                    this.markAccuracy(note.onsetState, MidiEventState.MISSED)
                    makeNoteWrong(note.graphics)
                    note.onsetState = MidiEventState.MISSED

                } else if (timestamp > note.noteOnTimingOffsets.correctEndTimestamp) {
                    // if(note.onsetState === MidiEventState.UNSET) {
                    //     this.markAccuracy(note.onsetState, MidiEventState.MISSED)
                    // }
                    // note.onsetState = MidiEventState.MISSED
                    // Correct window was missed, color mistimed
                    // REMOVED by request of Patrick and Gizzi
                    // makeNoteYellow(note.graphic)
                    // note.state = MidiEventState.MISSED
                    // Hmmm but should we factor accuracy?
                }
            })
        }

        // Look for held-down notes whose offset buckets were missed.
        this.activeOnsetEvents.forEach(note => {
            if (!this.checkAccuracyNoteHand(note, errorRecognitionActiveRight, errorRecognitionActiveLeft)) return
          
            if (note && note.offsetState == MidiEventState.UNSET 
                && note.noteOffTimingOffsets.mistimedEndTimestamp < timestamp)
            {
                if(note.onsetState === MidiEventState.UNSET) {
                    this.markAccuracy(note.onsetState, MidiEventState.MISSED)
                    note.offsetState = MidiEventState.MISSED
                    makeNoteWrong(note.graphics)
                } else if(note.onsetState === MidiEventState.HIT) {
                    this.markAccuracy(note.onsetState, MidiEventState.OFF_TIME)
                    makeNoteMistimed(note.graphics)
                    note.offsetState = MidiEventState.OFF_TIME
                } 
            }
        })
        // Advance the onset index if we've exceeded the max onset for this bucket.
        while (this.eventBuckets[this.onsetIndex] && timestamp > this.eventBuckets[this.onsetIndex].maxOnsetTimestamp) {
            this.eventBuckets[this.onsetIndex].notes.forEach(note => {
                if (!this.checkAccuracyNoteHand(note, errorRecognitionActiveRight, errorRecognitionActiveLeft)) return

                if (note.onsetState === MidiEventState.UNSET) {
                    this.markAccuracy(note.onsetState, MidiEventState.MISSED)
                    note.onsetState = MidiEventState.MISSED
                    makeNoteWrong(note.graphics)
                }
            })
            // this.eventBuckets[this.onsetIndex].errorMissedNotes()
            this.onsetIndex++
        }
    }

    private calculateTiedNoteLength(note: Note): number {
        if (note.NoteTie.Notes[0] == note) {
            return note.NoteTie.Duration.RealValue
        }
        return -1
    }

    // This differs from the above function in that it expects note data prerendered from the parsing lambda
    // private calculateTiedNoteLengthPrerendered(note: Tie): number {
    //     if (note.NoteTie.Notes[0] == note) {
    //         return note.NoteTie.Duration.RealValue
    //     }
    //     return -1
    // }
    
    private calculateCorrectNoteOffMin(noteDuration: number) {
        if (noteDuration <= 1.0/4.0) {
            return noteDuration * 0.5
        }
        // If duration is between a quarter note and half note, use this block.
        else if (noteDuration > 1.0/4.0 && noteDuration < 2.0/4.0) {
            const nRange = (2.0/4.0) - (1.0/4.0)
            // Scale from 0.5 to 0.625 of the note, on a nearly linear curve
            let nScale = 1.0 - ((2.0/4.0) - noteDuration) / nRange
            // nScale is normalized from 0..1; putting that on a curve nScale^0.6
            // lets a noteDuration=1.5 have minimum offset of just under 1 beat.
            // Adjust the following line to adjust how "aggressive" scaling between 1 and 2 is.
            // Use graphtoy.com to visualize the curve you're adjusting.
            nScale = Math.pow(nScale, 0.6)
            return (1.0/8.0) + (0.1875) * nScale
        } else {
            return noteDuration - (1.0/4.0) + (1.0/16.0)
        }
    }

    private pulseMatchedNote(note: NoteEvent, colorClass: string) {
        const vgNote = (note.graphics[0] as unknown as {staveNote: { id: string }})
        // const staveNote = vgNote.getSVGGElement()
        let staveNote = document.getElementById(vgNote.staveNote.id)
        // Notes with staves will report the wrong height; we have to grab a descendant
        // which is ONLY the note head so we calculate pulse height offset correctly.
        const noteHead = staveNote?.querySelector('.vf-notehead')

        if (noteHead == null) {
            console.error("note has no note head!!")
            return
        }
        
        // Get the bounding rect of the note head relative to the document body.
        // We will append a pulse to the document, and position it over the note.
        const boundingRect = noteHead.getBoundingClientRect()

        // Create the pulse.
        const pulse = document.createElement("div")
        pulse.classList.add("pulsating-circle", colorClass)
        document.body.appendChild(pulse)

        // Position the pulse.
        pulse.style.left = (boundingRect.x + boundingRect.width / 2) + "px"
        pulse.style.top = (window.scrollY + boundingRect.y + boundingRect.height / 2) + "px"

        // !!TODO AUSTIN!! You still need to track and delete the pulses!!!!!
    }

    private isOnsetTimeInBucket(timestamp: number, bucketIndex: number) {
        const minOnsetTimestamp = this.eventBuckets[bucketIndex].minOnsetTimestamp;
        const maxOnsetTimestamp = this.eventBuckets[bucketIndex].maxOnsetTimestamp;
        // console.log("minOnsetTimestamp = " + minOnsetTimestamp)
        // console.log("maxOnsetTimestamp = " + maxOnsetTimestamp )
        // console.log("timestamp = " + timestamp)
        // console.log( timestamp > minOnsetTimestamp
        //     && timestamp < maxOnsetTimestamp)
        return timestamp > minOnsetTimestamp
            && timestamp < maxOnsetTimestamp
    }

    private isInBucket(timestamp: number, bucketIndex: number) {
        const minOnsetTimestamp = this.eventBuckets[bucketIndex].minOnsetTimestamp;
        const maxOffsetTimestamp = this.eventBuckets[bucketIndex].maxOffsetTimestamp;
        // console.log("minOnsetTimestamp = " + minOnsetTimestamp)
        // console.log("maxOnsetTimestamp = " + maxOnsetTimestamp )
        // console.log("timestamp = " + timestamp)
        // console.log( timestamp > minOnsetTimestamp
        //     && timestamp < maxOnsetTimestamp)
        return timestamp > minOnsetTimestamp
            && timestamp < maxOffsetTimestamp
    }

    public clearErrors() {
        for (const eventBucket of this.eventBuckets ) {
            eventBucket.notes.forEach(note => {
                resetNote(note.graphics)
                note.offsetState = MidiEventState.UNSET
                note.onsetState = MidiEventState.UNSET
            })
        }
        this.accuracyQueue = []
    }
}