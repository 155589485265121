import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import PianoBackground from '../../assets/images/pianoBackground.png';
import Logo from 'Components/Logo';
import { CustomCircularProgress } from 'Components/StyledComponents';
import CheckIcon from '@mui/icons-material/Check';
import { handleAuthErrors } from 'Utils/Forms';
import { AmplifyAuthError } from 'Types';
import * as authActions from 'Actions/auth';
import { AuthReducer } from 'Types/AuthTypes';

const Verification: React.FC<any> = () => {

  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState("");

  const navigate = useNavigate();
  const search = useLocation().search.slice(1);
  const dispatch = useDispatch();
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  
  useEffect(()=>{
    console.log("am I logged in?")
    console.log(auth.user, auth.jwtToken)
    if(auth.user && auth.jwtToken) {
      navigate('/signin')
    }
  }, [auth.user, auth.jwtToken])

  useEffect(()=>{
    const query = new URLSearchParams(search);
    const params: {[key: string]: string} = {};
    for (let [key, value] of query.entries()) {
      params[key] = value;
    }

    if( params['user_name'] && params['confirmation_code'] && params['email']) {
      Auth.confirmSignUp(params['user_name'], params['confirmation_code'])
      .then(() => {
        setVerified(true)
        if( auth.tempSignUpPass && params['email']) {
          const email = params['email'].replace(' ', '+') // the "+" gets lost b/c it's assumed to be a url encoding
          dispatch(authActions.logIn({emailOrPhone: email, password: auth.tempSignUpPass, countryAcronym: ""}))
        } else {
          console.warn("pending email or phone number not found, so navigating to sign in")
          navigate('/signin')
        }
      })
      .catch((error: Error) => {
        const authError = error as unknown as AmplifyAuthError
        const err = handleAuthErrors(authError)
        console.log(err)
        // clear temp pass in case it's somehow the wrong one 
        dispatch(authActions.setTempSignUpPass(undefined))
        if(err?.code) setError(err.code)
        if(err?.unknown) setError(err.unknown)
      })
    } else {
      console.log("one of the attributes was not found")
      console.log(params)
      console.log(auth)
      navigate('/signin')
    }

    if(
      params['user_name'] && params['confirmation_code'] && params['email'] && auth.tempSignUpPass
    ) {
      Auth.confirmSignUp(params['user_name'], params['confirmation_code'])
      .then(() => {
        setVerified(true)
        if( auth.tempSignUpPass && params['email']) {
          const email = params['email'].replace(' ', '+') // the "+" gets lost b/c it's assumed to be a url encoding
          dispatch(authActions.logIn({emailOrPhone: email, password: auth.tempSignUpPass, countryAcronym: auth.pendingCountryAcronym || "US"}))
        } else {
          navigate('/signin')
          console.warn("pending email or phone or temp sign up pass not found.")
        }
      })
      .catch((error: Error) => {
        const authError = error as unknown as AmplifyAuthError
        const err = handleAuthErrors(authError)
        console.log(err)
        if(err?.code) setError(err.code)
        if(err?.unknown) setError(err.unknown)
      })
    } else {
      console.log("one of the attributes was not found")
      console.log(params)
      console.log(auth)
      
    }
  },[auth.tempSignUpPass])

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
    <img 
      style={{
        height: '100vh', 
        opacity: 0.3,
        position: 'absolute'
      }} 
      src={PianoBackground} 
      alt="piano-background">
    </img>
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
            <Logo/>
            <Box className="desc-container welcome-container">
           
              <Typography  variant='h3' className="title-welcome" style={{marginTop: '50px', }}>
                {!verified && "Verification Pending"}
                { verified && "Success" }
              </Typography>
              { ((!verified && !error) || (auth.processPending)) && <CustomCircularProgress/>}
              { verified && !error && !auth.processPending &&
                <CheckIcon  color='success' sx={{height:'80px', width: '80px'}}/>
              }
              { error && <Typography color="red">{error}</Typography>}
            </Box>
            
          </Box>
        </Box>
      </Box>      
    </Box>      
  )

}

export default Verification