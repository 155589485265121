import { EventState, EventActionTypes, EventActions, EventStateActions } from "Types/EventTypes";
import immutable from 'immutable';
import { v4 } from 'uuid'

export const defaultEventState: EventState = {
  sessionId: ""
}

export const eventReducer = (state = defaultEventState, action: EventActions | EventStateActions) => {
  if(process.env.REACT_APP_LOG_LEVEL == 'debug') {
    console.log("event  reducer: " + action)
  }
  switch(action.type) {
    case EventActionTypes.SET_SESSION_ID:
      return immutable.merge(
        state,
        {
          sessionId: v4(),
        }
      )
    default:
      return state
  }
} 