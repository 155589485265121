
import { Box, Button } from '@mui/material';  
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import { MainAppReducer } from 'Types';
// import { Levels } from '../../Constants/LevelData'
import LeftArrowIcon from '../../assets/images/LeftArrowIcon.png'
import Chevron1 from '../../assets/images/Chevron1.png'
import ChevronSingle from '../../assets/images/ChevronSingle.png'
import GirlPlayingPiano from '../../assets/images/GirlPlayingPiano.jpg'
import { useState, useEffect, useCallback } from 'react'
import * as appActions from 'Actions/app';
import * as eventActions from 'Actions/events';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import './Tutorial.css'
import { useAWSRumContext } from 'Contexts/AWSRum';
import { CustomCircularProgress } from 'Components/StyledComponents';
import {v4 as uuid} from 'uuid'
import { useComponentWillUnmount } from 'Utils';
import Fullscreen from 'Components/Fullscreen';
import { AuthReducer } from 'Types/AuthTypes';

const Tutorial = () => {


  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  let levelSelect = data.levelSelect
  const [currentCarousel, setCurrentCarousel] = useState(1)
  let curCarousel = data.tutorialData[currentCarousel-1]
  const totalCarousels = data.tutorialData.length

  // let totalCarousels = data.tutorialData.length
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    // init
    // Need to set phrases til next tier to 4 so that finished message doesn't pop up when
    // navigating to level
    dispatch(appActions.updatePhrasesTilNextTier(4));
  },[])

  useEffect(()=>{
    dispatch(eventActions.tutorialStartedEventAction(levelSelect));
  },[])

  useEffect(()=>{
    dispatch(eventActions.tutorialPageViewEventAction(levelSelect, currentCarousel));
  },[currentCarousel])

  // comment in the lines with setTutorialOpacity1 & 2 ( and set defaults below to 0 to show the transition effect)
  // Note that I will need to use ref and clear the animation
  // so that a user can go through different pages and it will resstart
  // these timeouts for transition
  const [tutorialOpacity1, setTutorialOpacity1] = useState(1)
  const [tutorialOpacity2, setTutorialOpacity2] = useState(1)
  useEffect(() => {
    if (tutorialOpacity1 == 0 && tutorialOpacity2 == 0) {
    setTimeout(() => {
      setTutorialOpacity1(1)
    }, 500)
    setTimeout(() => {
      setTutorialOpacity2(1)
    }, 3000)
    }
  }, [currentCarousel])

  const decTutorial = useCallback(() => {
    if (currentCarousel > 1) {
      setCurrentCarousel(currentCarousel-1)
    }
  }, [currentCarousel]);
  
  const incTutorial = useCallback(() => {
    if (currentCarousel < totalCarousels) {
      setCurrentCarousel(currentCarousel+1)
    } else {
      navigate(`/lesson`)
    }
  }, [currentCarousel, totalCarousels]);

  useEffect(() => {
    localStorage.setItem('hasReloaded', 'false');
  }, []);

  useEffect(() => {
    dispatch(appActions.fetchTutorialData())
  }, []);
  // Use arrow keys to navigate the tutoiral
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') decTutorial()
    if (e.key === 'ArrowRight') incTutorial()
  }, [decTutorial, incTutorial]);
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return data.tutorialDataProcessing ? 
  (
    <Box sx = {{display: 'flex', height: '100vh', width: '100vw', alignItems: 'center', justifyContent: 'center'}}>
      <CustomCircularProgress/>
    </Box>
  ) :
  (
    <Box sx = {{display: 'flex', flexDirection: 'column', height: '100vh'}}>
    <Header levelSelect={levelSelect} currentCarousel={currentCarousel} setCurrentCarousel={setCurrentCarousel} />
    <Box sx={{minHeight: '400px', height:'100%'}}>
      { curCarousel?.template_number == 1 && <Template1 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')}/> }
      { curCarousel?.template_number == 2 && <Template2 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')}/> }
      { curCarousel?.template_number == 3 && <Template3 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')}/> }
      { curCarousel?.template_number == 4 && <Template4 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')}/> }
      { curCarousel?.template_number == 5 && <Template5 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')}/> }
      { curCarousel?.template_number == 6 && <Template6 title={curCarousel.title} points={curCarousel.info_text.split('|')} srcs={curCarousel.srcs.split('|')} tutorialOpacity1={tutorialOpacity1} tutorialOpacity2={tutorialOpacity2}/> }

    </Box>
    <Footer 
      currentCarousel={currentCarousel} setCurrentCarousel={setCurrentCarousel} totalCarousels={totalCarousels}
      setTutorialOpacity1={setTutorialOpacity1} setTutorialOpacity2={setTutorialOpacity2}
      decTutorial={decTutorial} incTutorial={incTutorial}
    />
    </Box>
  )
}

// These are the layouts that will be rendered!!!
const Template1 = (props: any) => {
  const { title, points, srcs} = props
  return (
    <Box sx={{display:'flex', height: '100%', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{width:'50%', margin: '20px'}}>
        <img style={{width: '100%', backgroundColor: '#E2E2E2', borderRadius: '10px'}} src={`https://${srcs[0]}`}/>
      </Box>
      <Box sx={{width:'50%', margin: '20px'}}>
        {/* Template 1 */}
        <Box sx={{marginLeft: '30px', marginRight: '30px', textAlign: 'left'}}>
        <Box sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
        {TextBox(points)}
        </Box>
      </Box>
    </Box>
  )
}

const Template2 = (props: any) => {
  const { title, points, srcs } = props;
  return (
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', padding: '0px 20px 0px 20px', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{maxHeight: '40%', backgroundColor: '#E2E2E2', borderRadius: '5px', margin: '0px auto 10px auto', padding: '10px 6px 10px 6px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
        <img style={{maxHeight: '100%', maxWidth: '100%'}} src={`https://${srcs[0]}`}/>
      </Box>
      <Box sx={{margin: '0 auto auto 0', minHeight: `${50*points.length}px`, height: `${50*points.length}px`}}>
        <Box sx={{margin: '20px', textAlign: 'left'}}>
          <Box sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
          {TextBox(points)}
        </Box>
      </Box>
    </Box>
  );
}

const Template3 = (props: any) => {
  const { title, points, srcs} = props
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{maxHeight: '280px', margin: '0px 20px 10px 20px', overflow: 'hidden'}}>
        <img style={{maxHeight: '100%', maxWidth: '100%'}} src={`https://${srcs[0]}`}/>
      </Box>
      <Box sx={{minHeight: `${50*points.length}px`, height: `${50*points.length}px`}}>
        <Box sx={{margin: '20px 40px 40px 40px', textAlign: 'left'}}>
          <Box sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
          {TextBox(points)} 
        </Box>
      </Box>
    </Box>
  )
}

const Template4 = (props: any) => {
  const { title, points, srcs } = props;
  return (
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{height: '50%', maxHeight: '280px', margin: '0px 20px 10px 20px', overflow: 'hidden'}}>
        <img style={{maxHeight: '100%', maxWidth: '100%', backgroundColor: '#E2E2E2', borderRadius: '10px'}} src={`https://${srcs[0]}`}/>
      </Box>
      <Box sx={{margin: '0 auto auto 0', minHeight: '250px', height: '50%'}}>
        <Box sx={{textAlign: 'left', display: 'flex', height: '100%'}}>
          <Box sx={{margin: '30px 0px 30px 60px'}}>
            <img style={{maxHeight: '100%', borderRadius: '10px', backgroundColor: '#E2E2E2'}} src={`https://${srcs[1]}`}/>
          </Box>
          <Box sx={{margin: '30px'}}>
            <Box sx={{fontStyle: 'normal', marginBottom: '10px', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
            {TextBox(points)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Template5 = (props: any) => {
  const { title, points, srcs} = props
  return (
    // <Box>Tutorial2</Box>
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{margin: '10px 30px 9px 30px', maxHeight: '65%', maxWidth: '100%', backgroundColor: '#E2E2E2', borderRadius: '5px', padding: '10px 6px 20px 0px',
        display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Box sx={{display:'flex', margin: '5px 10px 0px 10px', maxHeight: '50%', maxWidth: '100%',  alignItems: 'center', justifyContent: 'center'}}>
          <img style={{maxHeight: '100%', maxWidth: '50%', marginRight: '4px'}} src={`https://${srcs[0]}`}/>
          <img style={{maxHeight: '100%', maxWidth: '50%', marginLeft: '4px'}} src={`https://${srcs[1]}`}/>
        </Box>
        <Box sx={{display:'flex', maxWidth: '50%', maxHeight: '50%', justifyContent: 'center'}}>
          <img style={{ maxWidth: '100%',}} src={`https://${srcs[2]}`}/>
        </Box>
      </Box>
      {/* Template 5 */}
      <Box sx={{height: '50%'}}>
        <Box sx={{textAlign: 'left', display: 'flex', height: '100%'}}>
          <Box sx={{margin: '30px 30px 30px 50px'}}>
            <Box sx={{fontStyle: 'normal', marginBottom: '15px', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
            {/* {points.map((k:String,v:number) => {
              return (
                <Box sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.01em', color: '#CED0D4'}}>
                  {k}
                </Box>
              )
            })} */}
            {TextBox(points)}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const Template6 = (props: any) => {
  const { title, points, srcs} = props
  const {
    tutorialOpacity1,
    tutorialOpacity2
  } = props
  
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)'}}>
      <Box sx={{
        margin: '10px 30px 9px 30px', 
        maxHeight: '50%', maxWidth: '100%', //backgroundColor: '#E2E2E2', 
        display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden'
      }}>
        <Box sx={{
          display:'flex', //marginTop: '5px', 
          maxWidth: '100%',  maxHeight: '100%', 
          alignItems: 'center', justifyContent: 'center',
        }}>
          <img style={{
            maxHeight: '100%', maxWidth: '50%', borderRadius: '10px', marginRight: '10px',
            opacity: tutorialOpacity1,
            transition: tutorialOpacity1 == 1 ? 'opacity 1s ease-in' : '',
            }} src={`https://${srcs[0]}`}/>
          <img style={{
            maxHeight: '100%', maxWidth: '50%', borderRadius: '10px', marginLeft: '10px',
            opacity: tutorialOpacity2,
            transition: tutorialOpacity2 == 1 ? 'opacity 1s ease-in' : '',
            }} src={`https://${srcs[1]}`}/>
        </Box>
      </Box>
      {/* Template 6 */}
      <Box sx={{margin: '0 auto auto auto', minHeight: points.length > 3 ? '325px':'225px', height: points.length > 3 ? '325px':'225px'}}>
        <Box sx={{textAlign: 'left', display: 'flex', height: '100%'}}>
          <Box sx={{margin: '30px 30px 30px 50px'}}>
            <Box sx={{fontStyle: 'normal', marginBottom: '15px', fontWeight: '400', fontSize: '34px', lineHeight: '41px', letterSpacing: '0.01em', color: '#CED0D4'}}>{title}</Box>
            {/* {points.map((k:String,v:number) => {
              return (
                <Box sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.01em', color: '#CED0D4'}}>
                  {k}
                </Box>
              )
            })} */}
            {TextBox(points)}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const TextBox = (textArr: any) => {
  return textArr.map((k:String,v:number) => {
    return (
      <Box key={`text-box-${v}`} sx={{marginBottom: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '17px', lineHeight: '19px', letterSpacing: '0.01em', color: '#CED0D4'}}>
        {k.replace(/<\/b>/g, '<b>').split('<b>').map((v, i) => {
          if (i%2 == 1) { return <b key={`b-${v}-${i}`}>{v}</b>
          } else {return v }
        })}
      </Box>
    )
  })
}

interface HeaderProps {
  levelSelect: number;
  currentCarousel: number;
  setCurrentCarousel: Function;
}

const Header = (props: HeaderProps) => {
  const { levelSelect } = props
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  
  const { levelData } = data
  const levelName = levelData[levelSelect].name
  const levelNumber = levelData[levelSelect].level_number

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onBack = () => {
    navigate('/roadmap')
  } 


  const chevronContainerStyles = {
    position: 'absolute',
    height:'100%'
  }

  const positionData = [
    { til: 4, src: Chevron1 },
    { til: 3, src: Chevron1 },
    { til: 2, src: Chevron1 },
    { til: 1, src: ChevronSingle },
  ];
  
  return (
    // <Box sx={{height: '160px', minHeight: '160px', display:'flex',flexDirection:'row', backgroundColor: 'red', position: 'relative'}}>
    <Box className="header-styles">
      <Box sx={{
        background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)',
        width:'100%', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'
        }}>
        <Box sx={{display:'flex', flexDirection:'row',alignItems:'center'}}>
          <Box sx={backcontainerStyles} onClick={() => onBack()}><img style={{height: '100%'}}src={LeftArrowIcon}/></Box>
          <Box sx={headerTextStyles}>Level {levelNumber}: {levelName}</Box>
          <Box sx={{
            marginLeft: '25px',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '0.01em',
            textDecorationLine: 'underline',
            color: '#ADB1B7',
            '&:hover': {
              cursor: 'pointer',
            },
          }} onClick={() => {
            dispatch(eventActions.tutorialSkippedEventAction(data.levelData[levelSelect].level_number));
            navigate(`/lesson`)}
          }>
            Skip Tutorial
          </Box>
        </Box>
    </Box>
    
    <Box className="header-container-styles">
      <Box sx={{height: '100%', display:'flex',flexDirection:'row', position: 'relative'}}>


      <Box className="tutorial-first-chevron-styles"/>
      {positionData.map((v, idx) => {
        const imgStyle = { height: '100%' };
        let imgSrc;
        imgSrc = v.src;
        return (
          <Box key={uuid()} className={`tutorial-left-position-${idx+1}`} sx={{ ...chevronContainerStyles }}>
            <img style={imgStyle} src={imgSrc} />
          </Box>
        );
      })}
      </Box>
      
      </Box>
    </Box>
  )
}

const headerTextStyles = {
  color:'#CA7C62',
  //fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '24px',
  letterSpacing: '0.01em'
}

const backcontainerStyles = {
  marginLeft: '80px', marginRight: '18px', height:'25px',
  '&:hover': {
      background: 'rgba(222,144,118,.5)',
      cursor: 'pointer',
      transition: '0.3s',
      borderRadius:'50%',
      boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
    },
}

const Footer = (props: any) => {
  const {
    currentCarousel, setCurrentCarousel, totalCarousels,
    decTutorial, incTutorial, setTutorialOpacity1, setTutorialOpacity2
  } = props
  const navigate = useNavigate();
  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: '#050B13',
      height: '100px',
      minHeight: '100px',
      justifyContent:'space-between',
      alignItems: 'center',
      // justifyContent: 'center',
      position: 'relative'
    }}>
      <Box sx={{position: 'absolute', top: 34, left: 30}}>
        <Fullscreen show_label={false}/>
      </Box>
      <Box sx={{marginLeft: '54px', marginRight: '-54px'}}>
        <Button 
          sx={{visibility: currentCarousel > 1 ? "" : "hidden"}}
          style={backButtonStyles} variant="contained" 
          onClick={() => {
            // setTutorialOpacity1(0)
            // setTutorialOpacity2(0)
            decTutorial()
          }}>Back
        </Button>
      </Box>
      <Box sx={{display: 'flex', marginLeft: '10px'}}>
        {/* This will export the number of components based on the content... */}
        {[...Array(totalCarousels)].map((k,v) => {
            return <Box key={`carousel-${v}`} sx={{
              width: '12px', height: '12px', 
              backgroundColor: v == currentCarousel-1 ? '#CA7C62' : '#5B646E', 
              borderRadius: '50%', marginRight: '10px'}}
            />
        })}
      </Box>

      <Box>
        <Button 
          style={nextButtonStyles} variant="contained" 
          onClick={() => {
            // setTutorialOpacity1(0)
            // setTutorialOpacity2(0)
            incTutorial()
          }}>
            { totalCarousels == currentCarousel ? "Start" : "Next" }
        </Button>
      </Box>

    </Box>
  )
}

const nextButtonStyles = {
  margin: '15px 40px 15px 40px',
  width: '114px', 
  color: 'black', 
  fontSize: '16px',
  background: '#CA7C62', 
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}
const backButtonStyles = {
  margin: '15px 40px 15px 40px',
  width: '114px', 
  color: '#CA7C62', 
  fontSize: '16px',
  border: '2px solid #CA7C62',
  background: 'none', 
  //boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12);', 
  borderRadius: '40px'
}

export default Tutorial
