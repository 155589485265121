import React, { useCallback, useEffect, useState } from 'react';
import { Box } from "@mui/system"
import CheckmarkSmall from '../../assets/images/CheckmarkSmall.png';
import CloseIcon from '../../assets/images/CloseIcon.png';
import { useNavigate } from 'react-router-dom';
import RoadmapTierComplete from '../../assets/images/RoadmapTierComplete.png'
import {  useDispatch, useSelector } from 'react-redux'
import * as appActions from 'Actions/app';
import { MainAppReducer, LevelStatus } from 'Types';
import { getLevelDataByLevelNumber } from 'Utils';
import { CustomButton } from 'Components';
import { range } from 'lodash'
import { AuthReducer } from 'Types/AuthTypes';
import LeftArrowIcon from '../../assets/images/LeftArrowIcon.png'
import { RepertoireReducer } from 'Types/RepertoireTypes';
import * as repertoireActions from '../../Actions/repertoire'

const TierComplete = (props: any) => {
  const dispatch = useDispatch();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const [newRepsUnlocked, setNewRepsUnlocked] = useState<any[]>([])
  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = repertoireReducerData?.repertoireData
  
  const levelData = data.levelData[data.levelSelect];
  const { showTierComplete,  setShowTierComplete, setAccuracy, setShowAccuracy } = props
  const navigate = useNavigate(); 
  const levelComplete = data?.currentUserLevelData?.current_tier && data?.currentUserLevelData?.current_tier >= data.currentLevelTiers

  
  useEffect(() => {
    if (levelComplete && repertoireData) {
      let newReps: any[] = []
      repertoireData?.forEach((rep) => {
        if (rep.level.level_number == levelData.level_number) {
          newReps.push(rep)
        }
      })
      setNewRepsUnlocked(newReps)
    }
  }, [levelComplete, repertoireData])

  const updateTier = useCallback((tier: number)=>{
    dispatch(appActions.updateCurrentULP({
      currentUserLevelProgress: {
        current_tier: tier// 3,
        // highest_tier_complete: 3,
        // status: LevelStatus.complete//"active"
      },
    }))
  },[dispatch, auth.jwtToken])

  // useEffect(() => {
  // dispatch(appActions.updateCurrentULP({
  //   authToken: user?.signInUserSession?.accessToken?.jwtToken,
  //   currentUserLevelProgress: {
  //     current_tier: 1,
  //     highest_tier_complete: 0,
  //     // status: LevelStatus.complete//"active"
  //   }
  // }))
  // }, [])

  if (!showTierComplete) { return <></>}
  return (
    <Box sx={{
      position: 'fixed',
      width: '60%',
      height: '60%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -45%)',
      zIndex: 3,
      background: 'linear-gradient(229.67deg, #1D2F44 -6.17%, #071423 89.04%)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', justifyContent: 'center'
    }}>

      <Box sx={{
        top: '20px', left: '16px', position: 'absolute',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.01em',
        color: '#CA7C62',
        '&:hover': {
          cursor: 'pointer'
        },
        display: 'flex', alignItems: 'center',
      }}
      onClick={() => {
        if(!levelComplete && data?.currentUserLevelData?.current_tier) {
          updateTier(data?.currentUserLevelData?.current_tier + 1)
        } else if(levelComplete) {
          dispatch(appActions.updatePhrasesTilNextTier(4))
        }
        setShowTierComplete(false)
        navigate('/roadmap')
      }}
      >
        <img style={{height: '16px', marginRight: '6px'}} src={LeftArrowIcon}/> Back to Roadmap
      </Box>

      <Box sx={{
        marginTop: '10px',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '34px',
        lineHeight: '41px',
        color: '#469F6A',
        letterSpacing: '0.01em',
      }}>{levelComplete ? `Level ${levelData.level_number}` : `Tier ${data?.currentUserLevelData?.current_tier} of ${data?.currentLevelTiers}`} Complete!</Box>
      { newRepsUnlocked.length == 0 && <Completion/>}
      { newRepsUnlocked.length > 0 &&
        <Box>
          <Box sx={{
            marginTop: '18px',
            fontWeight: '400',
            fontStyle: 'normal',
            fontSize: '24px',
            lineHeight: '41px',
            color: '#FFFFFF',
            letterSpacing: '0.01em',
          }}>
          Repertoire unlocked:
          </Box>

        <Box sx={{display: 'flex', gap: '16px', marginBottom: '10px'}}>
          { newRepsUnlocked.map((rep, index) => {
            const previewSrc = rep.repertoire_png_preview_url
            const title = rep.name
            let composer = ""
            rep.composers.forEach((comp: any, idx: number) => {
              composer = composer.concat(idx > 0 ? `, ${comp.name}`: comp.name)
            })

            if (index <= 3) {
              return (
                <Box sx={{
                  marginBottom: '20px', marginTop: '14px', minWidth: '160px', maxWidth: '160px', height: '180px', backgroundColor: '#07111D', borderRadius: '6px', border: `2px solid ${'#CA7C62'}`, position: 'relative', 
                  '&:hover': {
                    cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    dispatch(repertoireActions.setRepertoireSelect(rep.id));
                    // Timeout because we want to set the state before navigating and using wrong repertoireId for anything
                    // Not ideal, Same as the code in RepertoireItem.tsx
                    setTimeout(() => {
                      navigate('/repertoire-play')
                    }, 150)
                  }}
                >
                <Box sx={{width: '100%', height: '100px', backgroundColor: 'white', position: 'relative', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', borderBottom: `2px solid ${'#CA7C62'}`}}>
                  <Box sx={{paddingTop: '0px', height: '100px', overflow: 'hidden', marginLeft: '0px', marginRight: '0px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', }}>
                    <img style={{width: '100%'}} src={previewSrc}/>
                  </Box>
                  <Box sx={{...levelStyles, ...{backgroundColor: '#31353F'}}}>LVL {levelData.level_number}</Box>
                    <Box sx={{height: '74px', paddingBottom: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingLeft: '8px'}}>
                      <Box sx={titleStyles}>{title}</Box>
                      <Box sx={composerStyles}>{composer}</Box>
                    </Box>
                  </Box>
                </Box>
              )
            }
          })}
        </Box>
        </Box>
      }

      <Box
        sx={{
          width:'500px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 32px',
          gap: '8px',
          width: '160px',
          height: '28px',
          outlineColor: '#CA7C62',
          outlineStyle: 'solid',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
        }}
        onClick={async () => {
          await setShowAccuracy(false)
          await setShowTierComplete(false)
          await dispatch(appActions.updatePhrasesTilNextTier(4))
          await setAccuracy(null)
          await dispatch(appActions.resetLesson())
        }}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#CA7C62',

          }}>
            Replay Tier
          </Box>

        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 32px',
          gap: '8px',
          width: '160px',
          height: '28px',
          background: '#CA7C62',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
        }}
        onClick={() => {
          if(!levelComplete && data?.currentUserLevelData?.current_tier) {
            updateTier(data?.currentUserLevelData?.current_tier + 1)
            setShowTierComplete(false)
          } else if(levelComplete) {
            dispatch(appActions.updatePhrasesTilNextTier(4))
            dispatch(appActions.setLevelSelect({
              levelSelect: data.levelSelect + 1
            }))
            dispatch(appActions.fetchTutorialData())
            updateTier(1)
            setShowTierComplete(false)
            navigate('/tutorial')
          }
        }}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#050B13',
          }}>
            {"Keep Playing"}
          </Box>

        </Box>
      </Box>
      
      <Box sx={{position: 'absolute', left: '0px', top:'0px', width: '100%', height: '100%', zIndex: -100}}>
        <img src={RoadmapTierComplete} style={{width: '100%', height: '100%'}}/>
      </Box>

    </Box>
  )

}
export default TierComplete

const composerStyles = {
  marginBottom: '2px',
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: '700',
  lineHeight: '17px',
  letterSpacing: '0.01em',
  textAlign: 'left',
}

const titleStyles = {
  marginBottom: '7px',
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '17px',
  letterSpacing: '0.01em',
  textAlign: 'left',
}

const levelStyles = {
  position: 'absolute',
  left: '6px',
  bottom: '6px',
  borderRadius: '4px',
  padding: '0px 8px',

  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '1px',
  textAlign: 'left',

}

const Completion = () => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)

  return (

    <Box sx={{
      marginTop: '60px',
      marginBottom: '60px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '80%',
    }}>
      {
        range(1, data?.currentLevelTiers + 1).map((tier) => {
          const hasReachedTier = data?.currentUserLevelData?.current_tier && tier > data?.currentUserLevelData?.current_tier;
          return (
            <Box sx={{
              display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
            }}>
              {
                hasReachedTier ? (
                  <>
                    <Box sx={{
                      ...lineStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                    }}/>
                    <Box sx={{
                      ...circleStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      display: 'flex', alignItems: 'center', justifyContent: 'center', 
                    }}>
                      <img  src={CheckmarkSmall}/> {/*   The checkmark must be black  */}
                    </Box>
                  </>
                ) : 
                (
                  <>
                    <Box sx={{
                      ...lineStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      marginLeft: '0px',
                    }}/>
                    <Box sx={{
                    ...circleStyles,
                      backgroundColor: hasReachedTier ? '#31353F': '#6F87F1',
                      display: 'flex', alignItems: 'center', justifyContent: 'center', 
                      color: hasReachedTier ? '#6F87F1': '#31353F',
                    }}>
                      {tier}
                    </Box>
                  </>
                )
              }
            </Box>
            
          )
        })
    }
     
    </Box>
  )
}

const lineStyles = {
  height: '5px',
  // width: '200px',
  flexGrow: 1,
  borderRadius: '99px',
}

const circleStyles = {
  width: '26px',
  height: '26px',
  borderRadius: '50%',
}

